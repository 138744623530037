import React, { Component } from 'react';

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();
    this.state = {
      showIframe: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { scriptData, vastUrl, tag, selectedType } = this.props;
  
    if (
      prevProps.scriptData !== scriptData ||
      prevProps.vastUrl !== vastUrl ||
      prevProps.tag !== tag ||
      prevProps.selectedType !== selectedType
    ) {
      this.updateIframe();
    }
  
    if (
      prevProps.scriptData !== scriptData ||
      prevProps.vastUrl !== vastUrl ||
      prevProps.tag !== tag
    ) {
      this.setState({ showIframe: false }, () => {
        this.setState({ showIframe: true });
      });
    }
  }
  

  updateIframe = () => {
    const { scriptData, selectedType, vastUrl, tag } = this.props;
  
    if (!scriptData && !vastUrl && !tag) {
      return;
    }
  
    const event = {
      name: selectedType === 'va' ? 'LOAD_VAST_URL' : 'LOAD_SCRIPT_TAG',
      value: selectedType === 'va' ? tag : selectedType === 's' ? tag : null,
    };
  
    if (this.iframeRef.current && this.iframeRef.current.contentWindow) {
      this.iframeRef.current.contentWindow.postMessage(
        'preview-ad-tags:' + JSON.stringify(event),
        '*'
      );
    } else {
      console.log("Iframe ref or contentWindow is not available.");
    }
  };
  

  // Function to clean up the VAST URL by removing everything before the actual VAST URL
  cleanVastUrl = (url) => {
    // Remove everything before the second https://
    const match = url.split('https://')[1];
    return match ? `https://${match}` : ''; 
  };

  getSrcDoc = (vastUrl) => {
    const cleanedVastUrl = this.cleanVastUrl(vastUrl);
    return `
      <!DOCTYPE html>
      <html>
      <body>
          <div style="position: relative;background:#000;">
              <div id="LRAdContainer" class="LRIMAVast" style="height: 400px; position: relative;"></div>
              <div id="LRMuteControls" style="position:absolute;left:12px;top:12px;display: none;z-index:1000;">
                  <div id="LRMute" style="background-image:url('https://a.teads.tv/media/format/v3/assets/default/player-sprite.svg');
                  width: 16px;
                  height: 11px;
                  background-position: -445px 0;display:block;"></div>
                  <div id="LRUnmute" style="background-image:url('https://a.teads.tv/media/format/v3/assets/default/player-sprite.svg');
                  width: 16px;
                  height: 11px;
                  background-position: -390px 0;display:none;"></div>
              </div>
          </div>
          <style>
            #LRAdContainer.ad-playing {
              background:#000;
            }
          </style>
          <script src="https://imasdk.googleapis.com/js/sdkloader/ima3.js"></script>
          <script src="https://content.lumen-research.com/cachepages/release/ima/simple-player.v2.3.0.js"></script>
          <script>
              new SimplePlayer({
                  adTagUrl: "${cleanedVastUrl}",
                  playOn: "now",
                  adContainerSelector: "#LRAdContainer",
              });
          </script>
      </body>
      </html>
    `;
  };

  render() {
    const { scriptData, selectedType, vastUrl, tag } = this.props;
    const { showIframe } = this.state;

    const renderPreview = () => {
      if (selectedType === 's' && tag && tag.trim() !== '') {
        return (
          <div className="preview-tag" style={{ position: 'relative', width: '100%', height: '100%' }}>
          
          </div>
        );
      }
      else if (selectedType === 'va' && tag && tag.trim() !== '') {
        return (
          <div className="preview-tag" style={{ position: 'relative', width: '100%', height: '100%' }}>
          
          </div>
        );
      }

      return null;
    };

    return (
      <div className="preview-container" style={{ width: '100%', padding: '20px', boxSizing: 'border-box' }}>
        <h2 className="text-xl font-semibold mb-4 text-center">Preview</h2>

        <div className="iframe-wrapper" style={{ width: '100%', paddingBottom: '56.25%', position: 'relative' }}>
          {/* VAST Preview */}
          {showIframe && selectedType === 'va' && vastUrl && (
            <iframe
              onLoad={() => this.updateIframe()}
              className="iframe"
              title="vastPreview"
              srcDoc={this.getSrcDoc(vastUrl)}
              ref={this.iframeRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
              }}
            />
          )}

          {/* Script Preview */}
          {showIframe && selectedType === 's' && scriptData && (
            <iframe
              onLoad={() => this.updateIframe()}
              className="iframe"
              title="scriptPreview"
              srcDoc={scriptData}
              ref={this.iframeRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
              }}
            />
          )}

          {showIframe && (selectedType === 's' || selectedType === 'va') && tag && !scriptData && !vastUrl && (
            <div className="preview-tag" style={{ position: 'relative', width: '100%', height: '100%' }}>
              <h4>Tag Preview:</h4>
              <iframe
                srcDoc={tag}
                ref={this.iframeRef}
                title="tagPreview"
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',
                  display: 'block',
                }}
              />
            </div>
          )}

          {/* No Preview Data */}
          {!scriptData && !vastUrl && (
            <div className="preview-placeholder" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <p>No preview data available.</p>
            </div>
          )}
        </div>

        {renderPreview()}
      </div>
    );
  }
}

export default Preview;
