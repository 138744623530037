import React from 'react';
import { connect } from 'react-redux';
import { CellStepManagementOperations } from './duck/index';
import types from './duck/types';
import PropTypes from 'prop-types';
import Column from './components/Column';
import NewCellWrapper from './components/NewCellWrapper';
import Notification from './components/Notification';
import { DragDropContext } from 'react-beautiful-dnd';
import LoadingSpinner from '../common/components/LoadingSpinner';
import styled from 'styled-components';
import ModalRoot from '../Modal/ModalContainer';
import DataService from '../common/services/dataService';
import Grid from '@material-ui/core/Grid';
import { default as Box } from '@material-ui/core/Container';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';

const Container = styled.div`
`;

class CellStepManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studyId: '',
      item: '',
      value: '',
      active: false,
      shortName: '',
      nextList : [],
      density: 'standard',
      columns: [],
      columnOrder: [],
    };
    this.updateDensity = this.updateDensity.bind(this);
  }

  updateDensity(event) {
    this.setState({ 'density' : event.target.value });
  } 

  getList() {
    let  componentRef = this;
    DataService.getNextButton().then(function(resp) {
      componentRef.setState((state) => {
        return { ...state, nextList : resp.data };
      });
    });
  }

  componentDidMount() {
    const studyId = this.props.match.params.id;
    this.setState({ studyId });
    localStorage.setItem('studyId', studyId); 
    this.props.getStepData(studyId );
    this.getList();
    this.props.getNextButtonOptions();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.cellData !== this.props.cellData &&
      this.props.cellData &&
      this.props.cellData.columns &&
      this.props.cellData.columnOrder
    ) {
      this.setState({
        columns: this.props.cellData.columns,
        columnOrder: this.props.cellData.columnOrder
      });
    }
  }
  

  onDropDownSelect = (item) => {
    const id = this.props.match.params.id;
    this.props.onSelectItem(item, id);
  }


  onAddCellSubmit = (shortName, value) => {
    const { studyId } = this.state;
    const { addStepError, addCell } = this.props;

    if (value === '' && value === 0) { return false; }
    addCell(studyId, shortName, value);
    if (!addStepError) {
      this.setState({ active: false });
    }
  }

  onNewCellClick = () => {
    this.setState(prevState => ({
      active: !prevState.active
    }));
  }
  handleDuplicateColumn = async (column) => {
    const { studyId, columns, columnOrder } = this.state;
    let copyCount = parseInt(localStorage.getItem('copyCount') || '0', 10);
  
    try {
      const response = await DataService.duplicateCell(studyId, column.id);
  
      if (response?.data) {
        copyCount++;
        const newId = `${column.id}-duplicate-${copyCount}`;
  
        const duplicatedColumn = {
          ...column,
          id: newId,
          cellName: `${column.cellName} Copy ${copyCount}`,
          taskIds: [...column.taskIds],
        };
  
        this.setState({
          columns: {
            ...columns,
            [newId]: duplicatedColumn
          },
          columnOrder: [...columnOrder, newId]
        });
  
        localStorage.setItem('copyCount', copyCount);
      }
    } catch (err) {
      console.error("Duplication failed:", err);
    }
  };
  
  

  openModal(id) {
    const data = {
      type: types.SHOW_MODAL,
      modalType: types.ADD_STEP,
      modalProps: {
        modalOpen: this.props.modalOpen,
        onSelectItem: this.onDropDownSelect,
        handleClick: this.props.addTask,
        id: this.props.id,
        closeModal: this.props.closeModal,
        options: this.props.initialDropDown,
        dataFromPrimarySelection: this.props.dataFromPrimarySelection,
        onSecondarySelectItem: this.onSecondarySelectItem,
        studyId: this.state.studyId,
        loading: this.props.dropdownLoading,
        resetStateToLoading: this.props.resetStateToLoading,
        dropdownLoading: this.props.dropdownLoading,
      }
    };
    this.props.openModal(id, data);
  }

  openHamburgerModal(id, columnId, studyId) {
    this.props.getStepData(studyId);
    const data = {
      type: types.SHOW_MODAL,
      modalType: types.EDIT_DELETE_MODAL,
      modalProps: {
        modalOpen: this.props.hamburgerModalOpen,
        closeModal: this.props.closeHamburgerModal,
        id: this.props.id,
        onDeleteClick: (id, columnId) => this.props.deleteTask(id, columnId, this.state.studyId),
        columnId: this.props.columnId,
        openConfirmBox: this.props.openConfirmBox,
        closeConfirmBox: this.props.closeConfirmBox,
        confirmBoxOpen: this.props.confirmBoxOpen,
        closeEditBox: this.props.closeEditBox,
        openEditBox: this.props.openEditBox,
        editBoxOpen: this.props.editBoxOpen,
        onEditClick: (id, showNext, moveNext, nextNext) => this.props.editStepData(id, showNext, moveNext, nextNext, this.state.studyId),
        cellData: this.props.cellData,
        nextList: this.state.nextList
      }
    };
    this.props.openHamburgerModal(id, columnId, studyId, data);
  }

  render() {
    const { loading, cellData, onToggleLockStudy, isLocked } = this.props;
    const { studyId } = this.state;
    localStorage.setItem('studyId', this.state.studyId);
    const allowEditing = isLocked;
    if (loading) {
      return <LoadingSpinner />;
    }


    return (
      <Box style={{padding: '0px'}} maxWidth='xl'>
        <div style={{zIndex: 1, display:'flex', justifyContent:'flex-end',alignItems: 'center', gap:8, padding:'8px 8px 16px 8px', width: 'fit-content', position: 'fixed', top: 0, right: 132,}}>
          <Tooltip title={isLocked ? 'Study unlocked' : 'Study locked'}>
            <IconButton  size='medium' onClick={() => onToggleLockStudy(studyId, allowEditing)}  >
              {isLocked ? <LockOpenRoundedIcon style={{color: 'white', fontSize: 26}} /> : <LockRoundedIcon style={{color: 'white', fontSize: 26}} /> } 
            </IconButton>
          </Tooltip>
          <ToggleButtonGroup
            style={{background: 'white'}}
            color='primary'
            value={this.state.density}
            exclusive
            onChange={this.updateDensity}
          >
            <ToggleButton
              style={{    
                fontWeight: 500,
                fontsize: '0.875rem',
                lineHeight: 1.75,
                letterSpacing: '0.02857em',
                textTransform: 'uppercase',
                borderRadius: '4px',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,

                padding: '8px 12px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                color: 'rgba(0, 0, 0, 0.54)'
              }} 
              value='standard'>Standard</ToggleButton>
            <ToggleButton
              style={{    
                fontWeight: 500,
                fontsize: '0.875rem',
                lineHeight: 1.75,
                letterSpacing: '0.02857em',
                textTransform: 'uppercase',
                borderRadius: '4px',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            
                padding: '8px 12px',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                color: 'rgba(0, 0, 0, 0.54)'}}   value='dense'>Dense</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Grid  container style={{margin:'8px 0px', padding:'4px 8px 24px 8px', width: '100%',  overflowY: 'auto',}}  >        
          <DragDropContext onDragEnd={this.props.onDragEnd} >
            <Container style={{display: 'flex', gap: this.state.density === 'dense' ? '16px' : '24px'}} className='csm'>
            {this.state.columnOrder.map((columnId, index) => {
        const column = this.state.columns[columnId];
        if (!column) return null;

        const tasks = (column.taskIds || []).map((taskId) =>
          this.props.cellData.tasks?.[taskId?.step_order_id] || {}
        );

        return (
          <Column
            key={columnId}
            density={this.state.density}
            indexOrder={index}
            modalOpen={this.modalOpen}
            handleClick={(id) => this.openModal(id)}
            onHamburburgerClick={(id, columnId) =>
              this.openHamburgerModal(id, columnId, studyId)
            }
            column={column}
            tasks={tasks}
            addTask={this.addTask}
            onDeleteClick={(id, columnId) =>
              this.props.deleteTask(id, columnId, studyId)
            }
            studyId={this.state.studyId}
            allowEditing={allowEditing}
            onTitleChange={(id, title, TYPE) =>
              this.props.onTitleChange(id, title, TYPE, studyId)
            }
            cellId={columnId}
            onDuplicateColumn={this.handleDuplicateColumn}
            
          />
        );
      })}
              {allowEditing ?
                <React.Fragment>
                  <NewCellWrapper
                    onNewCellClick={this.onNewCellClick}
                    active={this.state.active}
                    onCancelClick={this.onNewCellClick}
                    onAddCellSubmit={this.onAddCellSubmit}
                  />     
                </React.Fragment>
                : null
              }
            </Container>
          </DragDropContext>
        </Grid>
        <Notification showNotification={this.props.showNotification} />
        <ModalRoot />
      </Box>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (id, data) => (dispatch(CellStepManagementOperations.openModal(id, data))),
    openHamburgerModal: (id, columnId, studyId, data) => (dispatch(CellStepManagementOperations.openHamburgerModal(id, columnId, studyId, data))),
    closeModal: () => (dispatch(CellStepManagementOperations.closeModal())),
    onDragEnd: (result) => (dispatch(CellStepManagementOperations.onDragEnd(result))),
    onSelectItem: (item, id) => (dispatch(CellStepManagementOperations.onSelectItem(item, id))),
    addTask: (id, value, studyId, item, cacheId, name) => (dispatch(CellStepManagementOperations.addTask(id, value, studyId, item, cacheId, name))),
    getStepData: (id) => (dispatch(CellStepManagementOperations.getStepData(id))),
    addCell: (id, shortName, value) => (dispatch(CellStepManagementOperations.addCell(id, shortName, value))),
    editStepData: (id, showNext, moveNext, nextNext, studyId) => (dispatch(CellStepManagementOperations.editStepData(id, showNext, moveNext, nextNext, studyId))),
    onTitleChange: (id, title, TYPE, studyId) => (dispatch(CellStepManagementOperations.editTask(id, title, TYPE, studyId))),
    resetStateToLoading: () => dispatch(CellStepManagementOperations.resetStateToLoading()),
    deleteTask: (id, columnId, studyId) => (dispatch(CellStepManagementOperations.deleteTask(id, columnId, studyId))),
    onToggleLockStudy: (id, isLocked) => (dispatch(CellStepManagementOperations.toggleLockStudy(id, isLocked))),
    getNextButtonOptions: () => (dispatch(CellStepManagementOperations.getNextButtonOptions()))
    

  };
};

const mapStateToProps = (state) => {
  return {
    modalOpen: state.cellStepManagement.modalOpen,
    hamburgerModalOpen: state.cellStepManagement.hamburgerModalOpen,
    id: state.cellStepManagement.id,
    cellData: state.cellStepManagement.cellData,
    dropDownType: state.cellStepManagement.dropDownType,
    loading: state.cellStepManagement.loading,
    dataFromPrimarySelection: state.cellStepManagement.dataFromPrimarySelection,
    initialDropDown: state.cellStepManagement.initialDropDown,
    rawCellData: state.cellStepManagement.rawCellData,
    addStepError: state.cellStepManagement.addStepError,
    columnId: state.cellStepManagement.columnId,
    dropdownLoading: state.cellStepManagement.dropdownLoading,
    showNotification: state.cellStepManagement.showNotification,
    isLocked: state.cellStepManagement.isLocked
  };
};

CellStepManagement.propTypes = {
  onSelectItem: PropTypes.func,
  closeModal: PropTypes.func,
  id: PropTypes.string,
  dropDownType: PropTypes.string,
  addTask: PropTypes.func,
  modalOpen: PropTypes.bool,
  openModal: PropTypes.func,
  cellData: PropTypes.shape({
    columns: PropTypes.object,
    tasks: PropTypes.object,
    columnOrder: PropTypes.array
  }),
  onDragEnd: PropTypes.func,
  deleteTask: PropTypes.func,
  match: PropTypes.object,
  getStepData: PropTypes.func,
  onTitleChange: PropTypes.func,
  onToggleLockStudy: PropTypes.func,
  openHamburgerModal: PropTypes.func,
  closeHamburgerModal: PropTypes.func,
  editStepData: PropTypes.func,
  addCell: PropTypes.func,
  getNextButtonOptions: PropTypes.func,
  openConfirmBox: PropTypes.func,
  closeConfirmBox: PropTypes.func,
  closeEditBox: PropTypes.func,
  openEditBox: PropTypes.func,
  editBoxOpen: PropTypes.func,
  resetStateToLoading: PropTypes.func,
  showNotification: PropTypes.bool,
  loading: PropTypes.bool,
  isLocked: PropTypes.bool,
  hamburgerModalOpen: PropTypes.bool,
  dropdownLoading: PropTypes.bool,
  confirmBoxOpen: PropTypes.bool,
  addStepError: PropTypes.string,
  columnId: PropTypes.string,
  initialDropDown: PropTypes.array,
  dataFromPrimarySelection: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(CellStepManagement);
