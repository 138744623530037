import axios from 'axios';
import { getHeaders } from './../common/shared';
import { URL } from './../common/constants';

const StimService = {
uploadStim: (data) => {
    return axios({
      method: 'post',
      url: `${URL}/v1.0/stims/create`,
      headers: getHeaders().headers,
      data: {
        name: data.name,
        stim_type: data.stim_type,
        ad_type_id: data.ad_type_id,
        brand_id: data.brand_id,
        source: data.source, 
        tag: data.tag
      }
    });
},
 
getPresignedUrl: (fileName) => {
  const { headers } = getHeaders(); 
  return axios({
    method: 'post',
    url: `${URL}/v1.0/stims/create_signed_url`,
    headers: headers, 
    data: { file_name: fileName },
  });
},


  deleteStim: (id) => {
    return axios({
      method: 'post',
      url: `${URL}/v1.0/stims/${id}/delete`,
      headers: getHeaders().headers,
      data: {}, 
    });
  },

  getStim: (id) => {
    return axios({
      method: 'get',
      url: `${URL}/v1.0/stims/${id}/details`,
      headers: getHeaders().headers,
    });
  },

  
  editStim: (id, data) => {
    return axios({
      method: 'post',
      url: `${URL}/v1.0/stims/${id}/edit`,
      headers: getHeaders().headers,
      data: data
    });
  },

  // getAdTypes: () => {
  //   return axios({
  //     method: 'get',
  //     url: `${URL}/v1.0/dashboard/data/adtypes_list/`,
  //     headers: getHeaders().headers
  //   });
  // },

  getAdAssetTypes: () => {
    return axios({
      method: 'get',
      url: `${URL}/v1.0/dashboard/data/adassettypes_list/`,
      headers: getHeaders().headers
    });
  },

  getAdTypeGroups: (adAssetTypeId) => {
    return axios({
      method: 'get',
      url: `${URL}/v1.0/dashboard/data/adtypegroups_list/`, // Endpoint URL
      params: { ad_asset_type_id: adAssetTypeId }, // Pass ID as query param
      headers: getHeaders().headers,
    }).catch((error) => {
      throw error; // Re-throw to allow catch block in useEffect to handle it
    });
  },
  
  getAdTypes: (adTypeGroupId) => {
    return axios({
      method: 'get',
      url: `${URL}/v1.0/dashboard/data/adtypes_list/`,
      params: {ad_type_group_id : adTypeGroupId},
      headers: getHeaders().headers
    }).catch((error) => {
      throw error; 
    });;
  },

  getBrands: () => {
    return axios({
      method: 'get',
      url: `${URL}/v1.0/dashboard/data/brands_list/`,
      headers: getHeaders().headers,
    });
  },
  getBrandCategories: () => {
    return axios({
      method: 'get',
      url: `${URL}/v1.0/dashboard/data/brandcategorys_list/`,
      headers: getHeaders().headers,
    });
  },
 
  createBrandCategory: (name, clean_name) => {
    return axios({
      method: 'post',
      url: `${URL}/v1.0/brandcategorys/create`,
      headers: getHeaders().headers,
      data: {
        name: name,
        clean_name: clean_name,
      },
    }).then((response) => {
      if (!response.data.success) {
        throw new Error('Failed to create brand category.');
      }
      return response.data;
    });
  },

  createBrand: (name, clean_name, brand_category_id) => {
    return axios({
      method: 'post',
      url: `${URL}/v1.0/brands/create`,
      headers: getHeaders().headers,
      data: {
        name: name,
        clean_name: clean_name,
        brand_category_id: brand_category_id,
      },
    }).then((response) => {
      if (!response.data.success) {
        throw new Error('Failed to create brand.');
      }
      return response.data;
    });
  },


//   getStudySpecificStims: (studyId, stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType) => {
//     return axios({
//       method: 'post',
//       url: `${URL}/v1.0/studies/${studyId}/stims`,
//       data: {
//         stimsPerPage,
//         pageNum,
//         orderBy,
//         filterName,
//         filterMediaId,
//         filterStimType,
//         showStudySpecific: true,
//       },
//       headers: getHeaders().headers,
//     });
//   },
  
//  getStimData: (studyId) => {
//     return axios({
//       method: 'post',
//       url: `${URL}/v1.0/studies/${studyId}/stims/overview`,
//       headers: getHeaders().headers
//     });
//   },
 
};

export default StimService;

