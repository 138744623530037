import React, { Component } from 'react';
import StimsTableContainer from './containers/StimsTableContainer';
import { connect } from 'react-redux';
import { stimsOperations } from './duck/index';
import LoadingSpinner from '../common/components/LoadingSpinner';
import DropDown from '../EditStudyDetails/components/DropDown';
import { addAllType } from '../Dashboard/DashboardContainer';
import ToolTip from '../common/components/Tooltip';
import Paper from "@material-ui/core/Paper";
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from "../common/components/Pagination";
import PrettyDropdown2 from "../common/components/PrettyDropDown2";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { withRouter } from 'react-router-dom';
import StimService from './StimService';
import DeleteIcon from '@mui/icons-material/Delete';

class Stims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      stimsPerPage: 25,
      pageNum: 1,
      orderBy: "name_asc",
      filterName: "",
      filterMediaId: 0,
      filterStimType: "",
      selectedStims: [] 
    };
    this.myTimer = React.createRef(null);
  }

  componentDidMount() {
    const { stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType } = this.state;
    const filterStudyId = parseInt(this.props.match.params.studyId) || null;
    this.props.getStims(stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType, filterStudyId)
    .then((stims) => {
      console.log("Fetched stims:", stims);
    });
  }
  goBackToOverview = () => {
    // this.props.history.push(`my_studies/overview/:studyId`);
    this.props.history.push(`/my_studies/overview/:studyId/study-specific-stims`);
  }
  // /my_studies/overview/study-specific-stims
  // /my_studies/overview/24
  
  componentWillUnmount() {
    this.props.fetchStimsBegin();  
  }

  onUploadStimClick = () => {
    this.props.history.push('/stims/upload-stim');
  };

  handlefilterMediaChange = (filterMediaId) => {
    this.setState({ filterMediaId }, this.reloadStims);
  };

  handlefilterStimTypeChange = (filterStimType) => {
    this.setState({ filterStimType }, this.reloadStims);
  };

  handlepageNumChange = (event, newPage) => {
    this.setState({ pageNum: newPage || 1 }, this.reloadStims);
  };

  handleStimsPerPageChange = (event) => {
    const stimsPerPage = event.target.value;
    this.setState({ stimsPerPage, pageNum: 1 }, this.reloadStims);
  };

  handleSortChange = (orderBy) => {
    this.setState({ orderBy }, this.reloadStims);
  };

  handleSearchChange = (filterName) => {
    this.setState({ filterName });
    clearTimeout(this.myTimer);
    this.myTimer = setTimeout(this.reloadStims, 500);
  };


    handleStimsSelection = (selectedStims) => {
      
      this.setState({ selectedStims });
      console.log('Selected Stims:', selectedStims);
    };
  
    handleDeleteStims = () => {
      const { selectedStims } = this.state;
       console.log('Selected Stims for Deletion:', selectedStims);
      if (selectedStims.length === 0) {
        alert('Please select stims to delete.');
        return;
      }
  
      if (window.confirm('Are you sure you want to delete the selected stims?')) {
        this.setState({ isDeleting: true });

        const deletePromises= selectedStims.map(stim=>{
          return StimService.deleteStim(stim);
        });
//promise.all will handle each id individually for deleting- api only deletes one stim (id) at a time
        Promise.all(deletePromises).then(()=>{
          alert('Stims deleted successfully.');
            this.setState({ selectedStims: [] });
            this.reloadStims(); 
          })
          .catch((error) => {
            console.error('Error deleting stims:', error);
          })
          .finally(() => {
            this.setState({ isDeleting: false });
        });
    
      }
    };

  reloadStims = () => {
    const { stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType } = this.state;
    this.props.getStims(stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType);
  };

  render() {
    const { loading, mediaTypes, stimTypes } = this.props;
    const {showFilter, filterName, stimsPerPage, isDeleting } = this.state;
    const studyId = this.props.match.params.studyId;

    return (
      <Paper style={{ borderRadius: 16 }}>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "16px", alignItems: "center" }}>
          <TextField
            style={{ zIndex: 0 }}
            autoFocus
            onChange={(e) => this.handleSearchChange(e.target.value)}
            value={filterName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            label="Search my stims..."
            variant="outlined"
          />

          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Tooltip placement="bottom" title="Filter stims">
              <IconButton onClick={() => this.setState({ showFilter: !showFilter })}>
                <FilterListIcon style={{ color: "rgb(99, 115, 129)" }} />
              </IconButton>
            </Tooltip>
{/* comditionally render the Upload button */}
            {!studyId && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              style={{ background: "#00badb", color: "white", height: 36, textTransform: "none", borderRadius: 8 }}
              onClick={this.onUploadStimClick}
            >
              Upload Stim
            </Button>
             )}

            <Button
  variant="contained"
  size="small"
  startIcon={<DeleteIcon />}
  style={{
    background: this.state.selectedStims.length === 0 ? "#b0bec5" : "#00badb", 
    color: "white",
    height: 36,
    textTransform: "none",
    borderRadius: 8,
  }}
  onClick={this.handleDeleteStims}
  disabled={this.state.selectedStims.length === 0 || isDeleting} 
>
  {isDeleting ? 'Deleting...' : 'Delete Selected'}
</Button>


          </div>
        </div>

        {loading ? (
          <LoadingSpinner style={{ padding: "25vh", margin: "auto" }} />
        ) : (
          <>
            {showFilter && (
              <div className="dashboard__filter-container" style={{ padding: 8, borderBottom: "none", borderRadius: 16 }}>
                <div className="dashboard__filter-container--item" style={{ padding: 8 }}>
                  <ToolTip content="Filter by media channel." />
                  <label>Media</label>
                  <PrettyDropdown2 options={mediaTypes} value={this.state.filterMediaId} onSelectItem={this.handlefilterMediaChange} />
                </div>
                <div className="dashboard__filter-container--item" style={{ padding: 8 }}>
                  <ToolTip content="Filter by stim types." />
                  <label>Stim Types</label>
                  <DropDown
                    className="left"
                    value={this.state.filterStimType ? this.state.filterStimType : "Select"}
                    onSelectItem={this.handlefilterStimTypeChange}
                    options={stimTypes.map((item) => item.description)}
                  />
                </div>
              </div>
            )}

            <div style={{ marginRight: 8 }}>
              <TablePagination
                rowsPerPage={stimsPerPage}
                onRowsPerPageChange={this.handleStimsPerPageChange}
                numberOfPages={this.props.stims.total_page_num}
                currentPage={this.props.stims.page_num}
                onPageChange={this.handlepageNumChange}
              />
            </div>

            <div className="stims__table-container">
              <StimsTableContainer
                currentOrderBy={this.state.orderBy}
                handleSortChange={this.handleSortChange}
                //pass to stimstable
                onStimsSelection={this.handleStimsSelection} 
              />
            </div>

            <div style={{ marginRight: 8 }}>
              <TablePagination
                rowsPerPage={stimsPerPage}
                onRowsPerPageChange={this.handleStimsPerPageChange}
                numberOfPages={this.props.stims.total_page_num}
                currentPage={this.props.stims.page_num}
                onPageChange={this.handlepageNumChange}
              />
            </div>
          </>
        )}
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    mediaTypes: addAllType(state.stimsReducer.mediaTypes),
    stimTypes: addAllType(state.stimsReducer.stimTypes),
    loading: state.stimsReducer.loading,
    stims: state.stimsReducer.stims,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getStims: (stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType, filterStudyId) => dispatch(stimsOperations.getStimsData(stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType, filterStudyId)),
    fetchStimsBegin: () => dispatch(stimsOperations.fetchStimsBegin()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Stims));




// import React, { Component } from 'react';
// import StimsTableContainer from './containers/StimsTableContainer';
// import { connect } from 'react-redux';
// import { stimsOperations } from './duck/index';
// import LoadingSpinner from '../common/components/LoadingSpinner';
// import DropDown from '../EditStudyDetails/components/DropDown';
// import { addAllType } from '../Dashboard/DashboardContainer';
// import ToolTip from '../common/components/Tooltip';
// import Paper from "@material-ui/core/Paper";
// import FilterListIcon from '@mui/icons-material/FilterList';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from "@material-ui/core/Tooltip";
// import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import SearchIcon from '@mui/icons-material/Search';
// import TablePagination from "../common/components/Pagination";
// import PrettyDropdown2 from "../common/components/PrettyDropDown2";
// import Button from '@mui/material/Button';
// import AddIcon from '@mui/icons-material/Add';
// import { withRouter } from 'react-router-dom';
// import StimService from './StimService';
// import DeleteIcon from '@mui/icons-material/Delete';

// class Stims extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       showFilter: false,
//       stimsPerPage: 25,
//       pageNum: 1,
//       orderBy: "name_asc",
//       filterName: "",
//       filterMediaId: 0,
//       filterStimType: "",
//       selectedStims: [],
//       showStudySpecific: false,
//       studySpecificStims: [],  
      
//     };
//     this.myTimer = React.createRef(null);
//   }

//   componentDidMount() {
//     const studyId = this.props.match.params.studyId;
//     console.log("studyIdddddd:", studyId);
//     if (studyId) {
//       this.setState({ showStudySpecific: true }, this.reloadStims);
//     } else {
//       this.setState({ showStudySpecific: false }, this.reloadStims);
//     }
//   }


//   componentWillUnmount() {
//     this.props.fetchStimsBegin();  
//   }

//   onUploadStimClick = () => {
//     this.props.history.push('/stims/upload-stim');
//   };

//   handlefilterMediaChange = (filterMediaId) => {
//     this.setState({ filterMediaId }, this.reloadStims);
//   };

//   handlefilterStimTypeChange = (filterStimType) => {
//     this.setState({ filterStimType }, this.reloadStims);
//   };

//   handlepageNumChange = (event, newPage) => {
//     this.setState({ pageNum: newPage || 1 }, this.reloadStims);
//   };

//   handleStimsPerPageChange = (event) => {
//     const stimsPerPage = event.target.value;
//     this.setState({ stimsPerPage, pageNum: 1 }, this.reloadStims);
//   };

//   handleSortChange = (orderBy) => {
//     this.setState({ orderBy }, this.reloadStims);
//   };

//   handleSearchChange = (filterName) => {
//     this.setState({ filterName });
//     clearTimeout(this.myTimer);
//     this.myTimer = setTimeout(this.reloadStims, 500);
//   };

//   handleStimsSelection = (selectedStims) => {
//     this.setState({ selectedStims });
//     console.log('Selected Stims:', selectedStims);
//   };

//   handleDeleteStims = () => {
//     const { selectedStims } = this.state;
//     console.log('Selected Stims for Deletion:', selectedStims);
//     if (selectedStims.length === 0) {
//       alert('Please select stims to delete.');
//       return;
//     }

//     if (window.confirm('Are you sure you want to delete the selected stims?')) {
//       this.setState({ isDeleting: true });

//       const deletePromises = selectedStims.map(stim => {
//         return StimService.deleteStim(stim);
//       });

//       Promise.all(deletePromises).then(() => {
//         alert('Stims deleted successfully.');
//         this.setState({ selectedStims: [] });
//         this.reloadStims();
//       })
//       .catch((error) => {
//         console.error('Error deleting stims:', error);
//       })
//       .finally(() => {
//         this.setState({ isDeleting: false });
//       });
//     }
//   };

//   reloadStims = () => {
//     const { stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType, showStudySpecific } = this.state;
//     const studyId = this.props.match.params.studyId;
  
//     console.log("Reloading stims with parameters---", {
//       stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType, studyId, showStudySpecific
//     });
  
//     if (showStudySpecific && studyId) {
//       this.props.getStudySpecificStims(stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType)
//         .then(response => {
//           console.log("API response:", response);
//           if (response && response.data) {
//             console.log("Data found---", response.data);
//             const stims = response.data.data || [];
//             console.log("Study specific stims fetched--", stims);
//             this.setState({ studySpecificStims: stims });
//           } else {
//             console.error("No data in response:", response);
//             this.setState({ studySpecificStims: [] });
//           }
//         })
//         .catch(error => {
//           console.error("Error fetching study specific stims--", error);
//           this.setState({ studySpecificStims: [] });
//         });
//     } else {
//       console.log("Fetching regular stims...");
//       this.props.getStims(stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType);
//     }
//   };
  
  

//   toggleStimView = () => {
//     this.setState(prevState => ({
//       showStudySpecific: !prevState.showStudySpecific
//     }), () => {
//       console.log("TOGGLE Stim View triggered, showStudySpecific:", this.state.showStudySpecific);
//       this.reloadStims();
//     });
//   };
// render() {
//     const { loading, mediaTypes, stimTypes } = this.props;
//     const { showFilter, filterName, stimsPerPage, isDeleting, showStudySpecific, studySpecificStims } = this.state;

//     const stimsLength = Array.isArray(studySpecificStims) ? studySpecificStims.length : 0;

//   console.log("T OR F?", showStudySpecific);
//   console.log("Study Specific Stims in RENDER:", studySpecificStims);
//   console.log("LENGTH", stimsLength);
//   console.log("Props stims:", this.props.stims);
//   console.log("showStudySpecific props:", this.state.studySpecificStims)


//   return (
//     <Paper style={{ borderRadius: 16 }}>
//       <div style={{ display: "flex", justifyContent: "space-between", padding: "16px", alignItems: "center" }}>
//         <TextField
//           style={{ zIndex: 0 }}
//           autoFocus
//           onChange={(e) => this.handleSearchChange(e.target.value)}
//           value={filterName}
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <SearchIcon />
//               </InputAdornment>
//             ),
//           }}
//           label="Search my stims..."
//           variant="outlined"
//         />
//         <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
//           <Tooltip placement="bottom" title="Filter stims">
//             <IconButton onClick={() => this.setState({ showFilter: !showFilter })}>
//               <FilterListIcon style={{ color: "rgb(99, 115, 129)" }} />
//             </IconButton>
//           </Tooltip>
//           <Button
//             variant="contained"
//             size="small"
//             startIcon={<AddIcon />}
//             style={{ background: "#00badb", color: "white", height: 36, textTransform: "none", borderRadius: 8 }}
//             onClick={this.onUploadStimClick}
//           >
//             Upload Stim
//           </Button>
//           <Button
//             variant="contained"
//             size="small"
//             startIcon={<DeleteIcon />}
//             style={{
//               background: this.state.selectedStims.length === 0 ? "#b0bec5" : "#00badb", 
//               color: "white",
//               height: 36,
//               textTransform: "none",
//               borderRadius: 8,
//             }}
//             onClick={this.handleDeleteStims}
//             disabled={this.state.selectedStims.length === 0 || isDeleting} 
//           >
//             {isDeleting ? 'Deleting...' : 'Delete Selected'}
//           </Button>
//         </div>
//       </div>

//       <div style={{ marginRight: 8 }}>
//         <TablePagination
//           rowsPerPage={stimsPerPage}
//           onRowsPerPageChange={this.handleStimsPerPageChange}
//           numberOfPages={this.props.stims.total_page_num}
//           currentPage={this.props.stims.page_num}
//           onPageChange={this.handlepageNumChange}
//         />
//       </div>

//       {loading ? (
//         <LoadingSpinner style={{ padding: "25vh", margin: "auto" }} />
//       ) : (
//         <>
//       {/* Display study-specific stims */}
//       {showStudySpecific ? (
//   <div className="stims__table-container">
//     <StimsTableContainer
//       currentOrderBy={this.state.orderBy}
//       handleSortChange={this.handleSortChange}
//       onStimsSelection={this.handleStimsSelection}
//       studySpecificStims={this.state.studySpecificStims} // Show study-specific stims when the toggle is on, regardless of length
//     />
//   </div>
// ) : (
//   <div className="stims__table-container">
//     <StimsTableContainer
//       currentOrderBy={this.state.orderBy}
//       handleSortChange={this.handleSortChange}
//       onStimsSelection={this.handleStimsSelection}
//       stims={this.props.stims}  // Show all stims when not showing study-specific
//     />
//   </div>
// )}


//           {/* Filter section */}
//           {showFilter && (
//             <div className="dashboard__filter-container" style={{ padding: 8, borderBottom: "none", borderRadius: 16 }}>
//               <div className="dashboard__filter-container--item" style={{ padding: 8 }}>
//                 <ToolTip content="Filter by media channel." />
//                 <label>Media</label>
//                 <PrettyDropdown2 options={mediaTypes} value={this.state.filterMediaId} onSelectItem={this.handlefilterMediaChange} />
//               </div>
//               <div className="dashboard__filter-container--item" style={{ padding: 8 }}>
//                 <ToolTip content="Filter by stim types." />
//                 <label>Stim Types</label>
//                 <DropDown
//                   className="left"
//                   value={this.state.filterStimType ? this.state.filterStimType : "Select"}
//                   onSelectItem={this.handlefilterStimTypeChange}
//                   options={stimTypes.map((item) => item.description)}
//                 />
//               </div>
//             </div>
//           )}

//           <div style={{ marginRight: 8 }}>
//             <TablePagination
//               rowsPerPage={stimsPerPage}
//               onRowsPerPageChange={this.handleStimsPerPageChange}
//               numberOfPages={this.props.stims.total_page_num}
//               currentPage={this.props.stims.page_num}
//               onPageChange={this.handlepageNumChange}
//             />
//           </div>
//         </>
//       )}
//     </Paper>
//   );
// }

// }  

// function mapStateToProps(state) {
//   return {
//     studySpecificStims: state.isolationData.StudySpecificStims,
//     loading: state.isolationData.loadingStudySpecificStims,
//     mediaTypes: addAllType(state.stimsReducer.mediaTypes),
//     stimTypes: addAllType(state.stimsReducer.stimTypes),
//     stims: state.stimsReducer.stims,
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//    getStims: (stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType) => dispatch(stimsOperations.getStimsData(stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType)),
//     fetchStimsBegin: () => dispatch(stimsOperations.fetchStimsBegin()),
//     getStudySpecificStims: (stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType, studyId) => dispatch(stimsOperations.fetchStudySpecificStims(stimsPerPage, pageNum, orderBy, filterName, filterMediaId, filterStimType, studyId)),
//     fetchStudyStimsBegin: () => dispatch(stimsOperations.fetchStudyStimsBegin()),
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Stims));


