import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import StimService from '../StimService'; 
import { fetchStudySpecificStims } from '../../Studies/duck/operations';
import { Link } from 'react-router-dom';  

const StudySpecificStimsContainer = ({
  loadingStudySpecificStims,
  StudySpecificStims,
  StudySpecificStimsErrors,
  fetchStudySpecificStims,
  history,
}) => {
  
  const path = window.location.pathname;
  const parts = path.split('/');
  const studyId = parts[parts.length - 1]|| 'defaultStudyId'; 

  const [currentStudyId, setCurrentStudyId] = useState(studyId);

  useEffect(() => {
    const fetchData = async () => {
      try {
      
        if (currentStudyId) {
          const response = await StimService.getStimData(currentStudyId);  
          console.log("Stim Data RESPINSE:", response);
          const data = response.data;

          if (data && data.length > 0) {
            const firstStim = data[0];
            setCurrentStudyId(firstStim.study_id); 
          }
        }
      } catch (error) {
        console.error('Error fetching study-specific stims:', error);
      }
    };

    fetchData();
  }, [currentStudyId]);  // Re-run if studyId changes

  if (loadingStudySpecificStims) {
    return (
      <div style={{ margin: 6 }}>
        <LoadingSpinner style={{ margin: '40px auto' }} />
      </div>
    );
  }

  if (!StudySpecificStims) {
    return '';
  }

  if (StudySpecificStims.data.length === 0 || StudySpecificStimsErrors) {
    return '';
  }

  return (
    <div style={{ margin: 6 }}>
      <div>
        <table style={{ width: '100%' }}>
          <tbody>
            {
              StudySpecificStims.data.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              }).map((stim, index) => {
                return (
                  <tr style={{ background: 'white' }} key={index}>
                    <td style={{ padding: '4px 0px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 'unset' }} className='study__image-wrapper'>
                          <div style={{ padding: '8px 0px', display: 'flex', alignItems: 'center' }} >
                            <Tooltip placement='bottom' title='Open stim in new tab'>
                              <a style={{ borderRadius: '50%', overflow: 'hidden' }} href={stim.source} target='_blank' rel='noreferrer' className='clickableAvatar'>
                                {stim.low_quality_source && stim.low_quality_source.split('.')[
                                  stim.low_quality_source.split('.').length - 1
                                ] === 'mp4' || stim.type === 'video' ?
                                  <div style={{ borderRadius: '50%', height: '100%', width: '100%', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <video style={{ height: '100%' }} muted autoPlay >
                                      <source src={stim.low_quality_source} type='video/mp4' />
                                    </video>
                                  </div>
                                  :
                                  !stim.low_quality_source ? <Avatar>{stim.name ? stim.name[0] : stim.brand[0]}</Avatar> :
                                    <img src={stim.low_quality_source}
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = 'https://s3.eu-west-2.amazonaws.com/content.lumen-research.com/cache_page_data/ImageAvatar.png';
                                      }}
                                      style={{ width: '100%', position: 'relative', top: '50%', transform: 'translateY(-50%)' }} alt='Image broken'
                                    />
                                }
                              </a>
                            </Tooltip>
                            <div style={{ padding: '0px 16px' }}>
                              <Typography variant='subtitle1'>{!stim.name ? '' : stim.name.length > 12 ? <Tooltip placement='right' title={stim.name}><p style={{ margin: 0, whiteSpace: 'nowrap' }} >{`${stim.name.substr(0, 11)}...`}</p></Tooltip> : stim.name} </Typography>
                              <Typography variant='caption'>{!stim.type ? '' : stim.type}</Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style={{ padding: '8px 12px', textAlign: 'right' }}>
                      <Button
                        style={{ padding: '3px 9px', border: 'unset', color: 'rgb(99, 115, 129)', borderRadius: 8, margin: 'auto' }}
                        component={Link}
                        to={`/stims/aoi/${stim.id}?type=stim`}
                        variant='outlined'
                        endIcon={<ChevronRightIcon />}
                      >
                        AOI
                      </Button>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3} style={{ padding: '12px 16px', textAlign: 'center', background: 'white' }} >
                {/* <Tooltip placement='right' title='View stims from other studies' >
                  <Button
                    style={{ padding: '3px 12px', border: '1px solid rgba(61, 73, 119, 0.5)', borderRadius: 8, color: '#3d4977', textTransform: 'capitalize' }}
                    component={Link}
                    to='/stims'
                    variant='outlined'
                    color='primary'
                  >
                    Other stims
                  </Button>
                </Tooltip> */}
                <Tooltip placement='right' title='View this stim' >
                  <Button
                    style={{ padding: '3px 12px', border: '1px solid rgba(61, 73, 119, 0.5)', borderRadius: 8, color: '#3d4977', textTransform: 'capitalize' }}
                    component={Link}
                    // to={`/my_studies/overview/study-specific-stims/${currentStudyId}`}  
                    to={`/my_studies/overview/${currentStudyId}/study-specific-stims`}  
                    variant='outlined'
                    color='primary'
                  >
                    Study Specific Stims
                  </Button>
                </Tooltip>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loadingStudySpecificStims: state.isolationData.loadingStudySpecificStims,
    StudySpecificStims: state.isolationData.StudySpecificStims,
    StudySpecificStimsErrors: state.isolationData.StudySpecificStimsError
  };
}

StudySpecificStimsContainer.propTypes = {
  loadingStudySpecificStims: PropTypes.bool,
  StudySpecificStims: PropTypes.object,
  StudySpecificStimsErrors: PropTypes.bool,
};

export default connect(mapStateToProps)(StudySpecificStimsContainer);
