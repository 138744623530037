import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const routes = [
  { path: '/my_studies/overview/:id/reports', breadcrumb: 'Reports' },
  { path: '/my_studies/overview', breadcrumb: null },
  // { path: '/my_studies/overview/:studyId/study/cell_id/:cell_id/step_id/:stepId/stim_id/:stimId/kpi', breadcrumb: 'KPI' },
  // { path: '/feature_analysis/:studyId/study/cell_id/:cellId/step_id/:stepId', breadcrumb: 'Feature Analysis' },
  { path: '/my_studies/overview/:studyId', breadcrumb: 'Overview' },
  // { path: '/my_studies/overview/:studyId/study/cell_id/:cell_id/step_id/:stepId/stim_id/:stimId', breadcrumb: null },
  // { path: '/my_studies/overview/:studyId/study/cell_id/:cell_id/step_id/:stepId/stim_id', breadcrumb: null },
  // { path: '/my_studies/overview/:studyId/study/cell_id/:cell_id/step_id', breadcrumb: null },
  // { path: '/my_studies/overview/:studyId/study/cell_id/:cell_id', breadcrumb: null },
  { path: '/my_studies/overview', breadcrumb: null },
  { path: '/my_studies/overview/:studyId/study/cell_id', breadcrumb: null },
  { path: '/stims/aoi/:id', breadcrumb: null },
  { path: '/batches/aoi/:id', breadcrumb: null },
  { path: '/batch_stim_list/aoi/:id', breadcrumb: null },
  { path: '/batch_stim_list', breadcrumb: 'Batch Stim List'},
  { path: '/cell_id', breadcrumb: null },
  { path: '/step_id', breadcrumb: null },
  { path: '/my_studies/overview/:studyId/study/cell_id/:cell_id/step_id/:stepId', breadcrumb: null },
  { path: '/attention_report', breadcrumb: 'Attention Report' },
  { path: '/my_batches/batch_overview/:id', breadcrumb: 'Batch Overview' },
  { path: '/my_batches/batch_overview/:id/batch_report', breadcrumb: 'Batch Report' },
  { path: '/my_batches/batch_overview', breadcrumb: null },
  { path: '/my_batches', breadcrumb: 'My Batches' },
  { path: '/my_studies/overview/:id', breadcrumb: null },
  { path: '/my_studies', breadcrumb:'My Studies' },
  { path: '/create-study/YouTube-study', breadcrumb:'YouTube study' },
  { path: '/', breadcrumb: 'Home' },
  { path: '/stims/edit', breadcrumb:null },
  { path: '/stims/edit/:id', breadcrumb:"Edit" },
  { path: '/stims', breadcrumb:"Stims" },
  { path: '/overview', breadcrumb: 'Overview' }, 
  // { path: '/my_studies/overview/study-specific-stims/:studyId', breadcrumb: 'Study Specific Stims' }, 
  { path: '/my_studies/overview/:studyId/study-specific-stims', breadcrumb: 'Study Specific Stims' }, 
  // { path: '/my_studies/overview/:studyId', breadcrumb:"null" },
  { path: '/my_studies/overview/:studyId', breadcrumb:"Overview" },
];

const styles = {
  sideBarOpen: {
    marginLeft: 320,
    position: "absolute", top: 15, display: "flex", alignItems: "center"
  },
  sideBarClosed: {
    marginLeft: 92,
    position: "absolute", top: 15, display: "flex", alignItems: "center"
  },
};



class Breadcrumbs extends Component {

  render() {
    const { classes } = this.props;
    return (
      <div className={this.props.side_bar_open ? classes.sideBarOpen : classes.sideBarClosed}  >
        <Link to="">
          <span />
        </Link>
        {this.props.breadcrumbs.map((breadcrumb, index) => {
          return (
            <span style={{ display: "flex", alignItems: "center" }} key={index}>
              {this.props.breadcrumbs.length === 1
                ? index === 0 && (
                  <Typography variant="h6" component="span"
                  // id={`${
                  //   this.props.side_bar_open
                  //     ? "pointer-first-home"
                  //     : "pointer-first"
                  // }`}
                  >
                    <Link style={{ textDecoration: "none", color: "white" }} to={breadcrumb.props.match.url}>{breadcrumb}</Link>
                  </Typography>
                )
                : index === 0 && (
                  <>
                    <Typography variant="h6" component="span"


                    >
                      <Link style={{ textDecoration: "none", color: "#89dcea" }} to={breadcrumb.props.match.url}>{breadcrumb}</Link>
                    </Typography>
                    <NavigateNextIcon style={{ color: "#89dcea", margin: 4 }} fontSize="small" />
                  </>
                )}
              {index < this.props.breadcrumbs.length - 1 && index > 0 && (
                <>
                  <Typography variant="h6" component="span" >
                    <Link style={{ textDecoration: "none", color: "#89dcea" }} to={breadcrumb.props.match.url}>{breadcrumb}</Link>
                  </Typography>
                  <NavigateNextIcon style={{ color: "#89dcea", margin: 4 }} fontSize="small" />
                </>
              )}
              {index === this.props.breadcrumbs.length - 1 && index > 0 && (
                <Typography variant="h6" component="span" >
                  <Link style={{ textDecoration: "none", color: "white" }} to={breadcrumb.props.match.url}>{breadcrumb}</Link>
                </Typography>
              )}
            </span>
          );
        })}
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    side_bar_open: state.homeReducer.side_bar_open,
  };
};


export default withRouter(
  connect(
    mapStateToProps
  )(withBreadcrumbs(routes)(withStyles(styles)(Breadcrumbs)))
);