import React from 'react';
import ToolTip from '../../common/components/Tooltip';
import TableSorter from "../../common/components/TableSorter"

function TableHeader({ data, handleSortChange, currentOrderBy }) {
  return (
    
    <tr style={{ paddingLeft: "32px",borderRadius: 8, boxShadow: "rgb(255 255 255) -16px 0px 0px inset"}}>
           {data.length > 1 && <th style={{ width: "32px" }}></th>}
      { data.map((item, index) => <React.Fragment key={index} >
      {item.name.toUpperCase() !== 'AOI' && item.name.toUpperCase() !== 'SOURCE'
          ? <th >
              <h4 style={{display:"inline"}} key={index} >
                  {item.tooltip ? <ToolTip className="top" content={item.content} /> : null}
                  {item.name}
                </h4>
                { currentOrderBy ? <TableSorter name={item.name} handleSortChange = {handleSortChange} currentOrderBy={currentOrderBy} /> : ""}
            </th>
          :  <th id={index}>
              <h4>
                {item.tooltip ? <ToolTip className="top" content={item.content} /> : null}
                {item.name}
              </h4>
            </th>}
          </React.Fragment>)}
    </tr>
  );
}

export default TableHeader;
