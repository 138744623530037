import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import { Link } from 'react-router-dom';
import TableHeader from './TableHeader';

function StimsTable({ stims, loading, tableHeadings, handleSortChange, currentOrderBy, onStimsSelection}) {
  const [selectedStims, setSelectedStims] = useState([]);
  // const activeTabElement = `${currentSort}-${sortType.toLowerCase()}`
  //const [isDeleting, setIsDeleting] = useState(false);

//triggered by onchange..
  const handleCheckboxChange = (e, stimId) => {
    const isChecked = e.target.checked;
    console.log(`Stim ID: ${stimId}, Checked: ${isChecked}`);
    setSelectedStims((prevSelected) => //updates selectedstims
      isChecked
        ? [...prevSelected, stimId] 
        : prevSelected.filter((id) => id !== stimId) 
    );
    onStimsSelection(isChecked ? [...selectedStims, stimId] : selectedStims.filter((id) => id !== stimId)); // Pass to parent

  };


  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    
    <table className="stims table">
      <thead>
        <TableHeader 
          currentOrderBy={currentOrderBy} 
          handleSortChange={handleSortChange} 
          data={tableHeadings} 
        />
      </thead>
      <tbody>
        {stims.map((item, index) => (
          <tr key={index}>
            <td>
              <input
                type="checkbox"
                checked={selectedStims.includes(item.id)} 
                onChange={(e) => handleCheckboxChange(e, item.id)} 
              />
            </td>
            <td>
            {/* <Link to={`/stims/edit/${item.id}`}><div>{item.name}</div></Link> */}
              <Link to={`/stims/edit/${item.id}`}><div>{item.name}</div></Link>
            </td>
            <td>{item.media}</td>
            <td>{item.brand}</td>
            <td>{item.width}</td>
            <td>{item.height}</td>
            <td>{item.id}</td>
            <td>{item.type}</td>
            <td className="links__table-headings">
              <Link to={`/stims/aoi/${item.id}?type=stim`}>
                <div className="button-primary" style={{padding:"4px 12px"}}>AOI</div>
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

StimsTable.propTypes = {
  loading: PropTypes.bool,
  stims: PropTypes.array.isRequired,
  tableHeadings: PropTypes.array.isRequired,
  handleSortChange: PropTypes.func.isRequired,
  currentOrderBy: PropTypes.string.isRequired,
  onStimsSelection: PropTypes.func.isRequired,
};

export default StimsTable;



// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import LoadingSpinner from '../../common/components/LoadingSpinner';
// import { Link } from 'react-router-dom';
// import TableHeader from './TableHeader';



// function StimsTable({ stims, loading, tableHeadings, handleSortChange, currentOrderBy }) {
//   // const activeTabElement = `${currentSort}-${sortType.toLowerCase()}`
//   const [selectedStims, setSelectedStims] = useState([]);
//   const [isDeleting, setIsDeleting] = useState(false);


//   // const handleCheckboxChange = (e, stimId) => {
//   //   const isChecked = e.target.checked;
//   //   setSelectedStims((prevSelected) =>
//   //     isChecked
//   //       ? [...prevSelected, stimId] 
//   //       : prevSelected.filter((id) => id !== stimId) 
//   //   );
//   // };
  
   
//    const handleCheckboxChange = (e, stimId) => {
//     const isChecked = e.target.checked;
//     setSelectedStims((prevSelected) =>
//       isChecked
//         ? [...prevSelected, stimId] 
//         : prevSelected.filter((id) => id !== stimId) 
//     );
//   };

//   // const handleDeleteClick = () => {
//   //   if (selectedStims.length > 0) {
//   //     setIsDeleting(true);
//   //     handleDelete(selectedStims).finally(() => {
//   //       setIsDeleting(false);
//   //       setSelectedStims([]); 
//   //     });
//   //   }
//   // };



  
//   if (loading) {
//     return (<LoadingSpinner />)
//   }
//   return (
    
//     <table className="stims table">
//       <tbody>
//         <TableHeader currentOrderBy={currentOrderBy} handleSortChange={handleSortChange}  data={tableHeadings} />
//         {stims.map((item, index) => {
//           return (
//             <tr key={index}>
//                  <td>
//               {/* <input
//                 type="checkbox"
//                 onChange={(e) => handleCheckboxChange(e, item.id)}
//                 checked={selectedStims.includes(item.id)} 
//               /> */}
//                             <input type="checkbox" checked={selectedStims.includes(item.id)} onChange={(e) => handleCheckboxChange(e, item.id)} />

//             </td>
//               {/* <td>{item.name}</td> */}
//               <Link to={`/stims/edit/${item.id}`}><div>{item.name}</div></Link>
//               <td>{item.media}</td>
//               <td>{item.brand}</td>
//               <td>{item.width}</td>
//               <td>{item.height}</td>
//               <td>{item.id}</td>
//               <td>{item.type}</td>
//               <td className="links__table-headings">
//                 <Link to={`/stims/aoi/${item.id}?type=stim`}><div  className="button-primary" style={{padding:"4px 12px"}} >AOI</div></Link>
//               </td>
//             </tr>
//           )
//         })}
//       </tbody>
//     </table>
//   );
// }

// export default StimsTable;


// StimsTable.propTypes = {
//   loading: PropTypes.bool,
//   stims: PropTypes.array,

//   //change IMPORTANNT
//   tableHeadings: PropTypes.array.isRequired,
//   handleSortChange: PropTypes.func.isRequired,
//   currentOrderBy: PropTypes.string.isRequired,
// };
