import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  FormHelperText,
  Stepper, 
  Step, 
  StepLabel, 
  StepContent,
  Modal,  
  Autocomplete,

} from '@mui/material';
import AWS from 'aws-sdk';
import StimService from '../StimService';
import Preview from './Preview';


const UploadStimPage = () => {
  
  const [adTypeGroups, setAdTypeGroups] = useState([]);
  const [selectedType, setSelectedType] = useState('s');
  const [scriptData, setScriptData] = useState('');
  const [vastUrl, setVastUrl] = useState('');
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState('');
  const [name, setName] = useState('');
  const [adTypeId, setAdTypeId] = useState('');
  const [adAssetTypeId, setAdAssetTypeId] = useState('');
  const [adTypeGroupId, setAdTypeGroupId] = useState('');
  const [brandId, setBrandId] = useState('');
  const [source, setSource] = useState('');
  const [tag, setTag] = useState('');
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [adTypes, setAdTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loadingAdTypes, setLoadingAdTypes] = useState(true);
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [isVideo, setIsVideo] = useState(false);
  const [loadingAdTypeGroups, setLoadingAdTypeGroups] = useState(true);
  const [loadingAdAssetTypes, setLoadingAdAssetTypes] = useState(true);  
  const [newBrandModalOpen, setNewBrandModalOpen] = useState(false); 
  const [newBrandName, setNewBrandName] = useState(''); 
  const [newBrandCategory, setNewBrandCategory] = useState(''); 
  const [categoryCreated, setCategoryCreated] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [brandCategories, setBrandCategories] = useState([]);

  const [errors, setErrors] = useState({
    name: '',
    adTypeId: '',
    brandId: '',
    scriptData: '',
    vastUrl: '',
    adAssetTypeId: '', 
  adTypeGroupId: '',  
  });

  const [adAssetTypes, setAdAssetTypes] = useState([]);

  const fileName = file ? file.name : '';
  const updatedFileName = fileName;

//1st level
// Fetch Ad Asset Types
useEffect(() => {
  const fetchAdAssetTypes = async () => {
    try {
      const response = await StimService.getAdAssetTypes();
      setAdAssetTypes(response.data);
    } catch (err) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        adAssetTypeId: 'Failed to load ad asset types.',
      }));
    } finally {
      setLoadingAdAssetTypes(false);
    }
  };
  fetchAdAssetTypes();
}, []);




// Fetch Ad Type Groups based on selected Ad Asset Type
useEffect(() => {
  if (!adAssetTypeId) return;
  const fetchAdTypeGroups = async () => {
    setLoadingAdTypeGroups(true);
    try {
      const response = await StimService.getAdTypeGroups(adAssetTypeId);
      setAdTypeGroups(response.data);
    } catch (err) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        adTypeGroupId: 'Failed to load ad type groups.',
      }));
    } finally {
      setLoadingAdTypeGroups(false);
    }
  };
  fetchAdTypeGroups();
}, [adAssetTypeId]);

  // Fetch Ad Types based on selected Ad Type Group
  useEffect(() => {
    if (!adTypeGroupId) return;
    const fetchAdTypes = async () => {
      setLoadingAdTypes(true);
      try {
        const response = await StimService.getAdTypes(adTypeGroupId);
        setAdTypes(response.data);
      } catch (err) {
        setErrors((prevErrors) => ({ ...prevErrors, adTypeId: 'Failed to load ad types.' }));
      } finally {
        setLoadingAdTypes(false);
      }
    };
    fetchAdTypes();
  }, [adTypeGroupId]);

    // Check if user is a super user
    useEffect(() => {
      const superUser = localStorage.getItem('superUser');
      setIsSuperUser(superUser === 'true'); 
    }, []);
  
  // Fetch brands
useEffect(() => {
  const fetchBrands = async () => {
    setLoadingBrands(true);
    try {
      const response = await StimService.getBrands();
      setBrands(response.data);
    } catch (err) {
      setError('Failed to load brands.');
    } finally {
      setLoadingBrands(false);
    }
  };
  fetchBrands();
}, []);

// Fetch brand categories
useEffect(() => {
  const fetchCategories = async () => {
    setLoadingCategories(true);
    try {
      const response = await StimService.getBrandCategories();
      setBrandCategories(response.data);
    } catch (err) {
      setError('Failed to load categories.');
    } finally {
      setLoadingCategories(false);
    }
  };
  fetchCategories();
}, []);



const handleBrandSelection = (event, selectedBrand) => {
  if (selectedBrand) {
    setBrandId(selectedBrand.id); 
    setErrors((prevErrors) => ({
      ...prevErrors,
      brandId: '', 
    }));
  } else {
    setBrandId(null);  
    setErrors((prevErrors) => ({
      ...prevErrors,
      brandId: 'Select a Brand',
    }));
  }

  if (newBrandModalOpen) {
    setNewBrandModalOpen(false);
    setNewBrandName('');
    setNewBrandCategory('');
  }
};


  useEffect(() => {
    const fetchAdTypes = async () => {
      if (!adTypeGroupId) return;
      setLoadingAdTypes(true);
      try {
        const response = await StimService.getAdTypes(adTypeGroupId);
        setAdTypes(response.data);
        if (response.data.length > 0) {
          setAdTypeId(response.data[0].id);
        }
      } catch (err) {
        setError('Failed to load ad types.');
      } finally {
        setLoadingAdTypes(false);
      }
    };
    
  
    if (adTypeGroupId) fetchAdTypes();
  }, [adTypeGroupId]);
  

  
  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    setScriptData('');
    setVastUrl('');
    setFile(null);
    setPreview('');
    setIsVideo(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(selectedFile);

      if (selectedFile.type.startsWith('video')) {
        setIsVideo(true);
      } else {
        setIsVideo(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
      'image/bmp': ['.bmp'],
      'video/mp4': ['.mp4'],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(file);
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
    },
  });

  const isXmlString = (str) => {
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(str, 'application/xml');
      return !xmlDoc.querySelector('parsererror');
    } catch (err) {
      return false;
    }
  };
  

  const handleCreateBrand = async () => {
    setError(null);
  
    if (!newBrandName || !newBrandCategory) {
      if (!newBrandName) {
        setError('Brand Name is required');
      }
      if (!newBrandCategory) {
        setError('Select a brand Category');
      }
      return;
    }
  
    try {

      await StimService.createBrand(newBrandName, newBrandName.toLowerCase(), newBrandCategory);
      
        
    const res = await StimService.getBrands();
    const updatedBrands = res.data;

   
    const matchedBrand = updatedBrands.find(
      (b) => b.name.toLowerCase() === newBrandName.toLowerCase()
    );

    if (!matchedBrand) {
      throw new Error('Brand was created but could not be found in updated list.');
    }

     setBrands(updatedBrands);
     setBrandId(matchedBrand.id); 
      setCategoryCreated(true);
      setNewBrandModalOpen(false);
      setNewBrandName('');
      setNewBrandCategory('');
      alert('Brand created successfully'); 
    } catch (error) {
      setError('Failed to create brand.');
    }
  };
  

  // Handle Ad Asset Type change and reset dependent fields
  const handleAdAssetTypeChange = (event, newValue) => {
    setAdAssetTypeId(newValue ? newValue.id : '');
    setAdTypeGroupId(''); 
    setAdTypeId(''); 

    setErrors((prevErrors) => ({
        ...prevErrors,
        adTypeGroupId: '', 
        adTypeId: '', 
    }));
};


  // Handle Ad type group change and reset Ad Type
  const handleAdTypeGroupChange = (event, newValue) => {
    setAdTypeGroupId(newValue ? newValue.id : ''); // Reset Ad Type Group
    setAdTypeId(''); // Reset Ad Type

    // Clear error for adTypeId
    setErrors((prevErrors) => ({
        ...prevErrors,
        adTypeId: '', 
    }));
};
  
  
  const handleUpload = async () => {
    setUploading(true);
    setError(null);
    setErrors({
      name: '',
      adTypeId: '',
      brandId: '',
      scriptData: '',
      vastUrl: '',
      adAssetTypeId: '',
      adTypeGroupId: '',
    });
  
    let formValid = true;

   // Validate adAssetTypeId and adTypeGroupId
   if (!adAssetTypeId) {
    formValid = false;
    setErrors((prevErrors) => ({
      ...prevErrors,
      adAssetTypeId: 'Ad Asset Type is required.',
    }));
  }
  
  if (!adTypeGroupId) {
    formValid = false;
    setErrors((prevErrors) => ({
      ...prevErrors,
      adTypeGroupId: 'Ad Type Group is required.',
    }));
  }

   
  // Handle brand selection errors based on user type
  if (isSuperUser) {
    if (newBrandModalOpen) {
      if (!newBrandCategory) {
        formValid = false;
        setErrors((prevErrors) => ({
          ...prevErrors,
          brandId: 'Select brand Category',
        }));
      }
      if (!newBrandName) {
        formValid = false;
        setErrors((prevErrors) => ({
          ...prevErrors,
          brandId: 'Brand Name is required',
        }));
      }
    } else {
      if (!brandId) {
        formValid = false;
        setErrors((prevErrors) => ({
          ...prevErrors,
          brandId: 'Please select a Brand',
        }));
      }
    }
  } else {
    if (!brandId) {
      formValid = false;
      setErrors((prevErrors) => ({
        ...prevErrors,
        brandId: 'Please select a Brand',
      }));
    }
  }
  
    if (selectedType === 's' && (!scriptData || !name || !adTypeId || !brandId)) {
      formValid = false;
      setErrors((prevErrors) => ({
        ...prevErrors,
        scriptData: !scriptData ? 'Script is required' : '',
        name: !name ? 'Name is required' : '',
        adTypeId: !adTypeId ? 'Ad Type is required' : '',
        brandId: !brandId ? 'Brand is required' : '',
      }));
    }
  
    if (selectedType === 'va' && (!vastUrl || !name || !adTypeId || !brandId)) {
      formValid = false;
      setErrors((prevErrors) => ({
        ...prevErrors,
        vastUrl: !vastUrl ? 'VAST URL is required' : '',
        name: !name ? 'Name is required' : '',
        adTypeId: !adTypeId ? 'Ad Type is required' : '',
        brandId: !brandId ? 'Brand is required' : '',
      }));
    }
  
    if (selectedType === 'iv' && (!file || !name || !adTypeId || !brandId)) {
      formValid = false;
      setErrors((prevErrors) => ({
        ...prevErrors,
        file: !file ? 'File is required' : '',
        name: !name ? 'Name is required' : '',
        adTypeId: !adTypeId ? 'Ad Type is required' : '',
        brandId: !brandId ? 'Brand is required' : '',
      }));
    }
  
    if (!formValid) {
      setUploading(false);
      return;
    }
  
    let stimType;
    let tagData = '';
    let sourceUrl = '';
  
    // File upload logic (for 's', 'va', 'iv' types)
    if (selectedType === 's') {
      stimType = 's';
      tagData = scriptData;
    } else if (selectedType === 'va') {
      if (isXmlString(vastUrl)) {
        stimType = 'vx';
        tagData = vastUrl;
      } else {
        stimType = 'va';
        tagData = vastUrl;
      }
    } else if (file) {
      const fileExt = file.name.split('.').pop().toLowerCase();
      if (fileExt === 'mp4') {
        stimType = 'v'; // Video
      } else if (['jpeg', 'jpg', 'png', 'gif', 'bmp'].includes(fileExt)) {
        stimType = 'i'; // Image
      }
  
      try {
        const response = await StimService.getPresignedUrl(fileName);
  
        if (response && response.data && response.data.url) {
          const presignedUrl = response.data.url;
          const uploadResponse = await fetch(presignedUrl, {
            method: 'PUT', 
            headers: {
              'Content-Type': file.type, 
            },
            body: file, 
          });
  
          if (!uploadResponse.ok) {
            const responseBody = await uploadResponse.text();
            throw new Error(`Failed to upload the file to S3. Status: ${uploadResponse.status}`);
          }
  
          sourceUrl = presignedUrl.split('?')[0];
        } else {
          throw new Error('No presigned URL returned.');
        }
      } catch (err) {
        setError('Failed to upload the file. Please try again.');
        setUploading(false);
        return;
      }
    }
  
    const data = {
      name,
      ad_type_id: adTypeId,
      brand_id: brandId,
      source: sourceUrl, 
      tag: tagData,
      stim_type: stimType,
      file_name: fileName, 
    };
  
    try {
      await StimService.uploadStim(data);
      alert('Upload successful');
      window.close();
      window.history.back();
    } catch (err) {
      setError('Upload failed. Please try again.');
    } finally {
      setUploading(false);
    }
  };
  
  
  return (
    <div
      style={{
        display: 'flex',
        justifyContent:
          (selectedType === 's' && scriptData) ||
          (selectedType === 'va' && vastUrl) ||
          (selectedType === 'iv' && preview)
            ? 'center'
            : 'center',
       
      }}
    >
      <div
        style={{
          display: 'flex',
          maxWidth: '1400px',
          width:
            (selectedType === 's' && scriptData) ||
            (selectedType === 'va' && vastUrl) ||
            (selectedType === 'iv' && preview)
              ? '100%'
              : 'auto',
          justifyContent:
            (selectedType === 's' && scriptData) ||
            (selectedType === 'va' && vastUrl) ||
            (selectedType === 'iv' && preview)
              ? 'center'
              : 'center',
              marginTop: '20px',
        }}
      >
        {/* Form Container */}
        <div
          style={{
            backgroundColor: '#fff',
            padding: '40px',
            borderRadius: '12px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '600px',
            marginRight: '20px',
          }}
        >
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              style={{
                fontSize: '18px',
                marginBottom: '10px',
                fontWeight: 'bold',
                textAlign: 'center', 
              }}
            >
              Choose Stim Type
            </FormLabel>
            <RadioGroup
              aria-label="stimType"
              name="stimType"
              value={selectedType}
              onChange={handleTypeChange}
              row
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                marginBottom: '20px',
                width: '100%',
              }}
            >
              <FormControlLabel
                value="s"
                control={<Radio style={{ display: 'none' }} />}
                label="SCRIPT"
                style={{
                  margin: '40px',
                  color: selectedType === 's' ? '#00badb' : '#000',
                  borderBottom: selectedType === 's' ? '2px solid #00badb' : 'none',
                  cursor: 'pointer',
                  paddingBottom: '5px',
                  transition: 'all 0.3s ease',
                }}
              />
              <FormControlLabel
                value="va"
                control={<Radio style={{ display: 'none' }} />}
                label="VAST URL/XML"
                style={{
                  margin: '40px',
                  color: selectedType === 'va' ? '#00badb' : '#000',
                  borderBottom: selectedType === 'va' ? '2px solid #00badb' : 'none',
                  cursor: 'pointer',
                  paddingBottom: '5px',
                  transition: 'all 0.3s ease',
                }}
              />
              <FormControlLabel
                value="iv"
                control={<Radio style={{ display: 'none' }} />}
                label="Image/Video"
                style={{
                  margin: '40px',
                  color: selectedType === 'iv' ? '#00badb' : '#000',
                  borderBottom: selectedType === 'iv' ? '2px solid #00badb' : 'none',
                  cursor: 'pointer',
                  paddingBottom: '5px',
                  transition: 'all 0.3s ease',
                }}
              />
            </RadioGroup>
          </FormControl>
  
          {/* Script Data Input */}
          {selectedType === 's' && (
            <TextField
              label="Script"
              required
              fullWidth
              multiline
              error={!!errors.scriptData}
              helperText={errors.scriptData}
              rows={4}
              value={scriptData}
              onChange={(e) => setScriptData(e.target.value)}
              style={{
                marginBottom: '20px',
                backgroundColor: '#f0f0f0',
                borderRadius: '0px',
              }}
            />
          )}
  
          {/* VAST URL Input */}
          {selectedType === 'va' && (
            <TextField
              label="Vast URL/XML"
              required
              fullWidth
              multiline
              error={!!errors.vastUrl}
              helperText={errors.vastUrl}
              rows={4}
              value={vastUrl}
              onChange={(e) => setVastUrl(e.target.value)}
              style={{
                marginBottom: '20px',
                backgroundColor: '#f0f0f0',
                borderRadius: '0px',
              }}
            />
          )}
  
          {/* Image/Video Upload */}
          {selectedType === 'iv' && (
            <div
              {...getRootProps()}
              style={{
                border: '2px dashed #ccc',
                padding: '20px',
                marginBottom: '20px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <p>Drag & Drop your image or video here, or click to browse</p>
              )}
              {errors.file && <FormHelperText error>{errors.file}</FormHelperText>}
            </div>
          )}
  
          {/* Name Field */}
          <TextField
            label="Name"
            fullWidth
            required
            error={!!errors.name}
            helperText={errors.name}
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              marginBottom: '20px',
              backgroundColor: '#f0f0f0',
              borderRadius: '0px',
            }}
          />

{/* ad Selection */}
<div>
  {/* Ad Asset Type Dropdown with Search inside */}

<FormControl
    fullWidth
    margin="normal"
    disabled={loadingAdAssetTypes}
    error={!!errors.adAssetTypeId}
>
    <Autocomplete
        options={adAssetTypes}
        getOptionLabel={(option) => option.name || ''}
        value={adAssetTypeId ? adAssetTypes.find(item => item.id === adAssetTypeId) : null}  // Bind value to adAssetTypeId
        onChange={handleAdAssetTypeChange}
        renderInput={(params) => (
            <TextField
                {...params}
                label="Ad Asset Type *"
                placeholder="Search Ad Asset Type"
                error={!!errors.adAssetTypeId}
                helperText={errors.adAssetTypeId}
            />
        )}
    />
</FormControl>

<FormControl
    fullWidth
    margin="normal"
    disabled={loadingAdTypeGroups || !adAssetTypeId}
    error={!!errors.adTypeGroupId}
>
    <Autocomplete
        options={adTypeGroups}
        getOptionLabel={(option) => option.name || ''}
        value={adTypeGroupId ? adTypeGroups.find(item => item.id === adTypeGroupId) : null}  // Bind value to adTypeGroupId
        onChange={handleAdTypeGroupChange}
        renderInput={(params) => (
            <TextField
                {...params}
                label="Ad Type Group *"
                placeholder="Search Ad Type Group"
                error={!!errors.adTypeGroupId}
                helperText={errors.adTypeGroupId}
            />
        )}
    />
</FormControl>

<FormControl
    fullWidth
    margin="normal"
    disabled={loadingAdTypes || !adTypeGroupId}
    error={!!errors.adTypeId}
>
    <Autocomplete
        options={adTypes}
        getOptionLabel={(option) => option.name || ''}
        value={adTypeId ? adTypes.find(item => item.id === adTypeId) : null}  // Bind value to adTypeId
        onChange={(event, newValue) => setAdTypeId(newValue ? newValue.id : '')}
        renderInput={(params) => (
            <TextField
                {...params}
                label="Ad Type *"
                placeholder="Search Ad Type"
                error={!!errors.adTypeId}
                helperText={errors.adTypeId}
            />
        )}
    />
</FormControl>

</div>


<div style={{ marginTop: '2rem' }}></div> 


<div>
  {/* Brand Selection with Search inside */}
  <FormControl fullWidth margin="normal" disabled={loadingBrands}>
  <Autocomplete
  options={brands}
  getOptionLabel={(option) => option.name || ''}
  onChange={handleBrandSelection}
  value={brandId ? brands.find((brand) => brand.id === brandId) : null}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select a Brand *"
      placeholder="Search Brand"
      error={!!errors.brandId}
      helperText={errors.brandId}  
    />
  )}
/>
  </FormControl>

  {/* Create Brand Button */}
  {isSuperUser && (
    <span
      style={{
        marginTop: '1rem',
        color: '#00badb',
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
      onClick={() => setNewBrandModalOpen(true)}
    >
      Create New Brand
    </span>
  )}

  {/* Create New Brand Modal */}
  <Modal open={newBrandModalOpen} onClose={() => setNewBrandModalOpen(false)}>
    <div
      style={{
        padding: '2rem',
        backgroundColor: 'white',
        margin: '10% auto',
        width: '400px',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        position: 'relative',
      }}
    >
      {/* Close button */}
      <span
        onClick={() => setNewBrandModalOpen(false)}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          cursor: 'pointer',
          fontSize: '1.5rem',
          color: '#000',
        }}
      >
        &times;
      </span>

      <h2>Create New Brand</h2>

      <FormControl fullWidth margin="normal" disabled={loadingCategories}>
        <Autocomplete
          options={brandCategories}
          getOptionLabel={(option) => option.name || ''}
          onChange={(event, newValue) => setNewBrandCategory(newValue ? newValue.id : '')}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a Category"
              placeholder="Search Category"
              error={!!error && !newBrandCategory}
              helperText={error && !newBrandCategory ? 'Category is required' : ''}
            />
          )}
        />
      </FormControl>

      <TextField
        label="Brand Name"
        value={newBrandName}
        onChange={(e) => setNewBrandName(e.target.value)}
        fullWidth
        margin="normal"
        error={!!error && !newBrandName}
        helperText={error && !newBrandName ? 'Brand Name is required' : ''}
      />

      <Button
        variant="contained"
        onClick={handleCreateBrand}
        style={{ marginTop: '1rem', backgroundColor: '#00badb', color: 'white' }}
      >
        Create Brand
      </Button>
    </div>
  </Modal>
</div>


          {/* Upload Button */}
          <Button
            variant="contained"
            style={{
              backgroundColor: '#00badb',
              color: '#fff',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '20px',
            }}
            onClick={handleUpload}
            disabled={uploading}
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </Button>
        </div>
  
        {/* Conditionally Render Preview Container */}
        {((selectedType === 's' && scriptData) ||
          (selectedType === 'va' && vastUrl) ||
          (selectedType === 'iv' && preview)) && (
          <div
            style={{
              backgroundColor: '#fff',
              width: '600px',
              padding: '20px',
              borderRadius: '12px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            {selectedType === 'iv' ? (
              <div>
                <h3 style={{ textAlign: 'center', marginBottom: '100px' }}>Preview</h3>
                {file && file.type.startsWith('image') ? (
                  <img
                    src={preview}
                    alt="Preview"
                    style={{
                      width: '100%',
                      maxWidth: '400px',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  />
                ) : (
                  <video
                    controls
                    style={{
                      width: '100%',
                      maxWidth: '400px',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <source src={preview} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ) : (
              <Preview
                selectedType={selectedType}
                scriptData={scriptData}
                vastUrl={vastUrl}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default UploadStimPage;






