import React, { Component } from 'react';
import StimService from '../StimService';
import { TextField, Button, Typography, MenuItem, Box, CircularProgress, Snackbar, Modal } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Autocomplete } from '@mui/material'; 
import Preview from './Preview'; 

class EditStim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      stimName: '',
      adtype: '',
      adAssetTypeId: '',
      adTypeGroupId: '',
      brand: '',
      availableAdTypes: [],
      availableAdAssetTypes: [],
      availableAdTypeGroups: [],
      availableBrands: [],
      isLoading: true,
      error: null,
      successMessage: null,
      source: null,
      stimtype: '',
      tag: null,
      errors: { name: false, adtype: false, brand: false },
      newBrandName: '',
      newBrandCategory: '',
      newBrandModalOpen: false, 
      isSuperUser: false, 
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
  
    const superUser = localStorage.getItem('superUser');
    this.setState({ isSuperUser: superUser === 'true' });
  
    Promise.all([
      StimService.getStim(id),
      StimService.getAdTypes(),
      StimService.getBrands(),
      StimService.getAdAssetTypes(),
    ])
      .then(([stimResponse, adTypesResponse, brandsResponse, adAssetTypesResponse]) => {
        const { name, ad_type_id, brand_id, source, stim_type, tag, ad_asset_type_id, ad_group_type_id } = stimResponse.data;
  
        this.setState({
          id,
          stimName: name,
          adtype: ad_type_id, 
          brand: brand_id,
          availableAdTypes: adTypesResponse.data,
          availableBrands: brandsResponse.data,
          availableAdAssetTypes: adAssetTypesResponse.data,
          adAssetTypeId: ad_asset_type_id || '',
          adTypeGroupId: ad_group_type_id || '' , 
          source,
          stimtype: stim_type,
          tag,
          isLoading: false,
        }, () => {

   if (ad_asset_type_id) {
    this.fetchAdTypeGroups(ad_asset_type_id);

  }
});
})
      .catch((error) => {
        this.setState({ isLoading: false, error: 'Error fetching stimulus details or dropdown options' });
      });
  }
  
  
  

handleInputChange = (e) => {
  const { name, value } = e.target;
  this.setState({ [name]: value });

  if (name === 'adAssetTypeId') {
    this.setState({ adTypeGroupId: '', adtype: '' }); 
    this.fetchAdTypeGroups(value);
  }

  if (name === 'adTypeGroupId') {
    this.setState({ adtype: '' }); 
    this.fetchAdTypes(value);
  }
};

fetchAdTypeGroups = (adAssetTypeId) => {
  StimService.getAdTypeGroups(adAssetTypeId)
    .then((response) => {
      const availableAdTypeGroups = response.data;
      

        const { adTypeGroupId } = this.state;

        const validAdTypeGroupId = availableAdTypeGroups.find(group => group.id === adTypeGroupId) ? adTypeGroupId : null;

        this.setState({ 
          availableAdTypeGroups, 
          adTypeGroupId: validAdTypeGroupId  

        });

    })
    .catch((error) => {
      this.setState({ error: 'Failed to load ad type groups' });
    });
};

fetchAdTypes = (adTypeGroupId, callback = () => {}) => {
  StimService.getAdTypes(adTypeGroupId)
    .then((response) => {
      const availableAdTypes = response.data;

      this.setState({
        availableAdTypes
      });

      callback();
    })
    .catch((error) => {
      this.setState({ error: 'Failed to load ad types' });
    });
};


handleModalOpen = async () => {
  try {
    const response = await StimService.getBrandCategories(); 
    this.setState({
      newBrandModalOpen: true,
      categories: response.data, 
      errors: { name: '', category: '', stimName: '' } 
    });
  } catch (error) {
    this.setState({ errors: { ...this.state.errors, category: 'Failed to load categories' } });
  }
};

handleCreateBrand = async () => {
  const { newBrandName, newBrandCategory } = this.state;
  let formIsValid = true;
  const newErrors = { brandName: '', category: '' };

  if (!newBrandName) {
    newErrors.brandName = 'Brand Name is required';
    formIsValid = false;
  }

  if (!newBrandCategory) {
    newErrors.category = 'Select a brand Category';
    formIsValid = false;
  }

  if (!formIsValid) {
    this.setState({ errors: newErrors });
    return;
  }

  try {
    // 1. Create the brand via API
    await StimService.createBrand(
      newBrandName,
      newBrandName.toLowerCase(),
      newBrandCategory.id
    );

    // 2. Re-fetch updated list of brands
    const res = await StimService.getBrands();
    const updatedBrands = res.data;

    // 3. Match the new brand by name
    const matchedBrand = updatedBrands.find(
      (b) => b.name === newBrandName
    );

    // 4. Set state with real int ID
    this.setState({
      availableBrands: updatedBrands,
      brand: matchedBrand ? matchedBrand.id : '',
      newBrandModalOpen: false,
      newBrandName: '',
      newBrandCategory: '',
      errors: { brandName: '', category: '' },
    });

    alert('Brand created successfully');
  } catch (error) {
    console.error('Brand creation failed:', error);
    this.setState({ error: 'Failed to create brand.' });
  }
};



handleSubmit = (e) => {
  e.preventDefault();
  const { stimName, adtype, adAssetTypeId, adTypeGroupId, brand } = this.state;
  let errors = {};


  if (!stimName) {
    errors.stimName = 'Stim Name is required'; 
  }

  if (!adAssetTypeId) {
    errors.adAssetTypeId = 'Ad Asset Type is required';
  }

  if (!adTypeGroupId) {
    errors.adTypeGroupId = 'Ad Type Group is required';
  }

  if (!adtype) {
    errors.adtype = 'Ad Type is required';
  }

  if (!brand) {
    errors.brand = 'Brand is required';
  }

  if (Object.keys(errors).length > 0) {
    this.setState({ errors });
    return;
  }

  StimService.editStim(this.state.id, {  
    name: stimName, 
    ad_type_id: adtype, 
    ad_asset_type_id: adAssetTypeId,
    ad_group_type_id: adTypeGroupId,
    brand_id: brand })
    .then((response) => {
      this.setState({ successMessage: 'Stimulus updated successfully' });
      this.props.history.push('/stims');
      
    })
    .catch(() => {
      this.setState({ error: 'Error updating stimulus. Please try again later.' });
    });
};


  
  render() {
    const {isSuperUser, stimName, adtype, adAssetTypeId, adTypeGroupId, brand, availableAdTypes, availableAdAssetTypes, availableAdTypeGroups, availableBrands, isLoading, error, successMessage, source, stimtype, tag, newBrandModalOpen, newBrandName, newBrandCategory, errors } = this.state;

    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      );
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <div
          style={{
            backgroundColor: '#fff',
            padding: '40px',
            borderRadius: '12px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            maxWidth: '700px',
            width: '100%',
            marginRight: '20px',
          }}
        >
          <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px', color: '#00badb' }}>
            EDIT STIM
          </Typography>
    
          {error && (
            <Snackbar open={Boolean(error)} autoHideDuration={6000}>
              <Alert severity="error">{error}</Alert>
            </Snackbar>
          )}
    
          {successMessage && (
            <Snackbar open={Boolean(successMessage)} autoHideDuration={6000}>
              <Alert severity="success">{successMessage}</Alert>
            </Snackbar>
          )}
    
          <form onSubmit={this.handleSubmit}>

          <TextField
        name="stimName"
        label="Stim Name *"
        value={stimName} 
        onChange={this.handleInputChange}
        variant="outlined"
        fullWidth
        error={!!errors.stimName} 
        helperText={errors.stimName ? 'Required' : ''}
        style={{
          marginBottom: '20px',
          backgroundColor: '#f0f0f0',
          borderRadius: '4px',
        }}
      />

<Autocomplete
  options={availableAdAssetTypes}
  getOptionLabel={(option) => option.name}
  value={availableAdAssetTypes.find(type => type.id === adAssetTypeId) || null}
  onChange={(e, newValue) => {
    this.handleInputChange({ target: { name: 'adAssetTypeId', value: newValue ? newValue.id : '' } });
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Ad Asset Type *"
      variant="outlined"
      fullWidth
      error={!!errors.adAssetTypeId}
      helperText={errors.adAssetTypeId ? 'Required' : ''}
      style={{
        marginBottom: '20px',
        backgroundColor: '#f0f0f0',
        borderRadius: '4px',
      }}
    />
  )}
/>

<Autocomplete
  options={availableAdTypeGroups}
  getOptionLabel={(option) => option.name}
  value={availableAdTypeGroups.find(group => group.id === adTypeGroupId) || null}
  onChange={(e, newValue) => {
    
    this.handleInputChange({ target: { name: 'adTypeGroupId', value: newValue ? newValue.id : '' } });
    // Clear 'adtype' when adTypeGroup changes
    this.handleInputChange({ target: { name: 'adtype', value: '' } });
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Ad Type Group *"
      variant="outlined"
      fullWidth
      error={!!errors.adTypeGroupId}
      helperText={errors.adTypeGroupId ? 'Required' : ''}
      style={{
        marginBottom: '20px',
        backgroundColor: '#f0f0f0',
        borderRadius: '4px',
      }}
    />
  )}
/>


<Autocomplete
  options={availableAdTypes}
  getOptionLabel={(option) => option.name}
  value={availableAdTypes.find(type => type.id === adtype) || null}
  onChange={(e, newValue) => {
    this.handleInputChange({ target: { name: 'adtype', value: newValue ? newValue.id : '' } });
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Ad Type *"
      variant="outlined"
      fullWidth
      error={!!errors.adtype}
      helperText={errors.adtype ? 'Required' : ''}
      style={{
        marginBottom: '20px',
        backgroundColor: '#f0f0f0',
        borderRadius: '4px',
      }}
    />
  )}
/>


            <Autocomplete
              options={availableBrands}
              getOptionLabel={(option) => option.name}
              key={brand}
              value={availableBrands.find(brandItem => brandItem.id === brand) || null}
              onChange={(e, newValue) => {
                this.setState({ brand: newValue ? newValue.id : '' });
              }}
                renderInput={(params) => (
                <TextField
                  {...params}
                  label="Brand *"
                  variant="outlined"
                  fullWidth
                  error={this.state.errors.brand}
                  helperText={this.state.errors.brand ? 'Required' : ''}
                  style={{
                    marginBottom: '20px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '4px',
                  }}
                />
              )}
            />

            {/* Link to open the modal */}
             {isSuperUser && (
          <Typography
            onClick={this.handleModalOpen} 
            style={{
              color: '#00badb',
              textDecoration: 'underline',
              cursor: 'pointer',
              marginTop: '10px',
              textAlign: 'center',
            }}
          >
            Create new brand
          </Typography>
        )}
            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: '#00badb',
                color: '#fff',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '20px',
              }}
            >
              Save Changes
            </Button>
          </form>
        </div>

        {/* Create Brand Modal */}
        <Modal
          open={newBrandModalOpen}
          onClose={() => this.setState({ newBrandModalOpen: false })}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              padding: '30px',
              borderRadius: '8px',
              width: '400px',
              position: 'relative',
            }}
          >
            {/* Close Modal Button */}
            <Typography
              onClick={() => this.setState({ newBrandModalOpen: false })}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                cursor: 'pointer',
                fontSize: '18px',
                fontWeight: 'bold',
              }}
            >
              X
            </Typography>

            <Typography variant="h6" style={{ marginBottom: '20px' }}>
              Create New Brand
            </Typography>

            {/* Modal Content */}
            <TextField
              label="Brand Name *"
              value={newBrandName}
              onChange={(e) => this.setState({ newBrandName: e.target.value })}
              variant="outlined"
              fullWidth
              style={{
                marginBottom: '20px',
                backgroundColor: '#f0f0f0',
                borderRadius: '4px',
              }}
              error={this.state.errors.name !== ''}
              helperText={this.state.errors.name}
            />


<Autocomplete
  value={newBrandCategory}
  onChange={(e, newValue) => this.setState({ newBrandCategory: newValue })}
  options={this.state.categories || []}
  getOptionLabel={(option) => option.name || ''}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Brand Category *"
      variant="outlined"
      fullWidth
      style={{
        marginBottom: '20px',
        backgroundColor: '#f0f0f0',
        borderRadius: '4px',
      }}
      error={this.state.errors.category !== ''}
      helperText={this.state.errors.category}
    />
  )}
/>


            <Button
              onClick={this.handleCreateBrand}
              variant="contained"
              style={{
                backgroundColor: '#00badb',
                color: '#fff',
                width: '100%',
                marginTop: '20px',
              }}
            >
              Create Brand
            </Button>
          </div>
        </Modal>
        
        {/* Preview Container */}
        <div
          style={{
            maxWidth: '700px',
            width: '100%',
            backgroundColor: 'white', 
            padding: '20px',           
            borderRadius: '12px',      
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
          }}
        >
          {stimtype === 'v' || stimtype === 'i' ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <Typography variant="h6" gutterBottom>
                Preview
              </Typography>
              {source.match(/\.(mp4|webm|ogg)$/i) ? (
                <video controls style={{ maxWidth: '100%', maxHeight: '300px' }}>
                  <source src={source} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={source} alt="Stimulus preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
              )}
            </div>
          ) : (
            <Preview
              selectedType={stimtype}
              tag={tag}
              scriptData={tag}
              vastUrl={tag}
            />
          )}
        </div>
      </div>
    );
    
  }
}

export default EditStim;