import React, { Component } from 'react';
import DashboardTableContainer from './containers/DashboardTableContainer';
import { connect } from 'react-redux';
import { dashboardOperations } from './duck/index';
import { stimsOperations } from '../Stims/duck/index';
import CheckBoxStyled from '../common/components/Checkbox';
import PrettyDropdown2 from '../common/components/PrettyDropDown2';
import PropTypes from 'prop-types';
import LoadingSpinner from '../common/components/LoadingSpinner';
//import Modal from './components/Modal';
import ToolTip from '../common/components/Tooltip';
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Tooltip from "@material-ui/core/Tooltip"
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TablePagination from "../common/components/Pagination"

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      stimsPerPage: 25,
      pageNum: 1,
      orderBy:"name_asc",
      filterName: "",
      filterLive: "-1",
      isCompleted: "-1",
      filterMediaId: 0,
      filterEnvironmentId: 0,
    }
    this.myTimer = React.createRef(null)
  }

  componentDidMount() {
    this.props.getStudies(this.state.stimsPerPage, this.state.pageNum, this.state.orderBy, this.state.filterName, this.state.filterLive, this.state.isCompleted, this.state.filterMediaId, this.state.filterEnvironmentId);
    this.props.getTypes()
  }

  onCreateStudyClick = () => {
    this.props.history.push(`/create-study`);
  }



  handlepageNumChange = (event, newPage) => {
    if (newPage) {
      this.setState({pageNum: newPage})
      this.props.getStudies(parseInt(this.state.stimsPerPage), parseInt(newPage), this.state.orderBy, this.state.filterName, this.state.filterLive, this.state.isCompleted, this.state.filterMediaId, this.state.filterEnvironmentId);
    }
  }
  
    handleStimsPerPageChange = (event) => {
      this.setState({pageNum: 1})
      this.setState({stimsPerPage: event.target.value})
      this.props.getStudies(event.target.value, 1, this.state.orderBy, this.state.filterName, this.state.filterLive, this.state.isCompleted, this.state.filterMediaId, this.state.filterEnvironmentId);
    }

    handleSortChange = (orderBy) => {
      this.setState({orderBy: orderBy})
      this.props.getStudies(parseInt(this.state.stimsPerPage), parseInt(this.state.pageNum), orderBy, this.state.filterName, this.state.filterLive, this.state.filterMediaId, this.state.filterEnvironmentId);
    }
    handleSearchChange = (filterName) => {
      this.setState({filterName: filterName})
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.props.getStudies(parseInt(this.state.stimsPerPage), 1, this.state.orderBy, filterName, this.state.filterLive, this.state.isCompleted, this.state.filterMediaId, this.state.filterEnvironmentId);
      }, 500);
    }

    handleFilterLiveChange = (filterLive) => {
      this.setState({filterLive: filterLive})
      this.props.getStudies(parseInt(this.state.stimsPerPage), 1, this.state.orderBy, this.state.filterName, filterLive, this.state.isCompleted, this.state.filterMediaId, this.state.filterEnvironmentId);
    }

    handleIsCompletedChange = (filterIsCompleted) => {
      this.setState({isCompleted: filterIsCompleted})
      this.props.getStudies(parseInt(this.state.stimsPerPage), 1, this.state.orderBy, this.state.filterName, this.state.filterLive,  filterIsCompleted, this.state.filterMediaId, this.state.filterEnvironmentId);
    }

    handlefilterMediaChange = (mediaCode) => {
      console.log(mediaCode)
      this.setState({filterMediaId: mediaCode})
      this.props.getStudies(parseInt(this.state.stimsPerPage), 1, this.state.orderBy, this.state.filterName, this.state.filterLive,  this.state.isCompleted, mediaCode, this.state.filterEnvironmentId);
    }

    handlefilterEnvironmentChange = (environmentCode) => {
      this.setState({filterEnvironmentId: environmentCode})
      this.props.getStudies(parseInt(this.state.stimsPerPage), 1, this.state.orderBy, this.state.filterName, this.state.filterLive,  this.state.isCompleted, this.state.filterMediaId, environmentCode);
    }

  render() {
    const { loading,  environmentTypes, mediaTypes, typesLoading } = this.props;
 
    return (
      <React.Fragment>
        <div className="dashboard">
          <Paper  style={{ borderRadius:16, paddingBottom:16}}>
            <div style={{display:"flex", padding:16,  justifyContent: "space-between"}}>
            <TextField
            style={{zIndex:0}}
            autoFocus
            onChange={(e) => this.handleSearchChange(e.target.value)}
            value={this.state.filterName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            id="outlined-basic" label="Search my studies..." variant="outlined" />
            <div style= {{display:"flex", alignItems: "center", gap: 24}}>
              <Tooltip title="Filter studies">
                <IconButton onClick ={() => {this.setState({showFilter: !this.state.showFilter})}}  aria-label="filter results" component="span">
                    <FilterListIcon style={{color: "rgb(99, 115, 129)"}} />
                  </IconButton>
                  </Tooltip>
                  
                    <Button variant="contained" 
                    size="small"
                    startIcon={<AddIcon style={{color:"rgb(255, 255, 255)"}} />}
                    style={{ background: "#00badb", color: "rgb(255, 255, 255)", height:36,  textTransform: "none", borderRadius:8}}
                onClick={this.onCreateStudyClick}
                data-testid="createStudy"
                title="Creates new study."
            >Create Study</Button>

            </div>
              </div>
          { loading || typesLoading ? <LoadingSpinner /> : <>
         {this.state.showFilter ?  <div className="dashboard__filter-container">
      
            <div className="dashboard__filter-container--item">
              <ToolTip content="Filter by live studies." />
              <label>Live</label>

              <CheckBoxStyled id="Live" checked={this.state.filterLive === "1"} className="dashboard__filter-container--item--checkbox" handleClick={() => this.handleFilterLiveChange(this.state.filterLive === "1" ? "-1" : "1")} />

            </div>
            <div className="dashboard__filter-container--item">
              <ToolTip content="Filter by completed studies." />
              <label>Completed</label>
              <CheckBoxStyled id="Completed" checked={this.state.isCompleted === "1"} className="dashboard__filter-container--item--checkbox" handleClick={() => this.handleIsCompletedChange(this.state.isCompleted === "1" ? "-1" : "1")} />

            </div>
            <div className="dashboard__filter-container--item">
              <ToolTip content="Filter by media channel." />
              <label>Media</label>
              <PrettyDropdown2 options={mediaTypes}  value={this.state.filterMediaId}  onSelectItem={this.handlefilterMediaChange} />
            </div>
            <div className="dashboard__filter-container--item">
              <ToolTip content="Filter by study environments." />

              <label>Environment</label>
              <PrettyDropdown2 options={environmentTypes}  value={this.state.filterEnvironmentId}  onSelectItem={this.handlefilterEnvironmentChange} />
            </div>
          </div> : "" }
          <div style={{ marginRight: 8}}>
          <TablePagination  rowsPerPage={this.state.stimsPerPage}  onRowsPerPageChange={this.handleStimsPerPageChange} numberOfPages={this.props.fetchAllStudysInfo.total_page_num} currentPage={this.props.fetchAllStudysInfo.page_num } onPageChange={this.handlepageNumChange}  />
        </div>
            <DashboardTableContainer handleSortChange={this.handleSortChange} currentOrderBy={this.props.fetchAllStudysInfo.order_by}  />
            </>}
           </Paper>
           </div>
        

      </React.Fragment >
    );
  }
}


Dashboard.propTypes = {
  studyData: PropTypes.array,
  isStudyLive: PropTypes.bool,
  isStudyCompleted: PropTypes.bool,
  active_study_count: PropTypes.number,
  completed_study_count: PropTypes.number,
  accountData: PropTypes.object,
  isLive: PropTypes.func,
  isCompleted: PropTypes.func,
  media: PropTypes.func,
  environment: PropTypes.func,
  search: PropTypes.func,
  getStudies: PropTypes.func,
  loading: PropTypes.bool,
  onAscendingButtonClick: PropTypes.func,

};

export function addAllType(type) {
  if (type) {
    return [{ description: 'All', code: 0 }, ...type]
  }

}
//Need to delete the now redundant props
function mapStateToProps(state) {
  return {
    studyData: state.studyData.studies,
    currentSort: state.studyData.currentSort,
    isStudyLive: state.studyData.isLive,
    accountData: state.studyData,
    value: state,
    loading: state.studyData.loading,
    openNewStudyModal: state.studyData.openNewStudyModal,
    environmentLoading: state.studyData.environmentLoading,
    environmentData: state.studyData.environmentData,
    modalOpen: state.studyData.openNewStudyModal,
    mediaData: state.studyData.mediaData,
    studyTypes: state.studyData.studyTypes,
    environmentTypes: addAllType(state.stimsReducer.environmentTypes),
    mediaTypes: addAllType(state.stimsReducer.mediaTypes),
    studyTypesData: state.stimsReducer.studyTypes,
    typesLoading: state.stimsReducer.typesLoading,
    languageTypes: state.studyData.languageTypes,
    fetchAllStudysInfo: state.studyData.fetchAllStudysInfo


  };
}

function mapDispatchToProps(dispatch) {
  return {
    media: (media) => (dispatch(dashboardOperations.setMediaFilter(media))),
    environment: (environment) => (dispatch(dashboardOperations.setEnvironmentFilter(environment))),
    getStudies: (stimsPerPage, pageNum, orderBy, filterName, filterLive, filterIsCompleted, mediaId, filter_environment_id) => dispatch(dashboardOperations.getStudyData(stimsPerPage, pageNum, orderBy, filterName, filterLive, filterIsCompleted, mediaId, filter_environment_id)),
    getTypes: () => dispatch(stimsOperations.getTypes()),
    fetchStudiesBegin: () => dispatch(dashboardOperations.fetchStudiesBegin()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
