
const FETCH_STIMS_BEGIN = 'FETCH_STIMS_BEGIN';
const FETCH_STIMS_SUCCESS = 'FETCH_STIMS_SUCCESS';
const FETCH_STIMS_ERROR = 'FETCH_STIMS_ERROR';
const SET_STIM_FILTER = 'SET_STIM_FILTER';
const GET_TYPES_BEGIN = 'GET_TYPES_BEGIN';
const GET_TYPES_SUCCESS = 'GET_TYPES_SUCCESS';
const SET_STIM_TYPE_FILTER = 'SET_STIM_TYPE_FILTER';
const SET_MEDIA_FILTER = 'SET_MEDIA_FILTER';
const SORT_BY_ASCENDING_ORDER = 'SORT_BY_ASCENDING_ORDER';
const SORT_BY_DECENDING_ORDER = 'SORT_BY_DECENDING_ORDER';

export default {
  FETCH_STIMS_BEGIN,
  FETCH_STIMS_SUCCESS,
  FETCH_STIMS_ERROR,
  SET_STIM_FILTER,
  GET_TYPES_BEGIN,
  GET_TYPES_SUCCESS,
  SET_STIM_TYPE_FILTER,
  SET_MEDIA_FILTER,
  SORT_BY_ASCENDING_ORDER,
  SORT_BY_DECENDING_ORDER
};

// const FETCH_STIMS_BEGIN = 'FETCH_STIMS_BEGIN';
// const FETCH_STIMS_SUCCESS = 'FETCH_STIMS_SUCCESS';
// const FETCH_STIMS_ERROR = 'FETCH_STIMS_ERROR';
// const SET_STIM_FILTER = 'SET_STIM_FILTER';
// const GET_TYPES_BEGIN = 'GET_TYPES_BEGIN';
// const GET_TYPES_SUCCESS = 'GET_TYPES_SUCCESS';
// const SET_STIM_TYPE_FILTER = 'SET_STIM_TYPE_FILTER';
// const SET_MEDIA_FILTER = 'SET_MEDIA_FILTER';
// const SORT_BY_ASCENDING_ORDER = 'SORT_BY_ASCENDING_ORDER';
// const SORT_BY_DECENDING_ORDER = 'SORT_BY_DECENDING_ORDER';
// //....
// const FETCH_STUDY_STIMS_BEGIN = 'FETCH_STUDY_STIMS_BEGIN';
// const FETCH_STUDY_STIMS_SUCCESS = 'FETCH_STUDY_STIMS_SUCCESS';
// const FETCH_STUDY_STIMS_ERROR = 'FETCH_STUDY_STIMS_ERROR';


// export default {
//   FETCH_STIMS_BEGIN,
//   FETCH_STIMS_SUCCESS,
//   FETCH_STIMS_ERROR,
//   SET_STIM_FILTER,
//   GET_TYPES_BEGIN,
//   GET_TYPES_SUCCESS,
//   SET_STIM_TYPE_FILTER,
//   SET_MEDIA_FILTER,
//   SORT_BY_ASCENDING_ORDER,
//   SORT_BY_DECENDING_ORDER,
//   //...
//   FETCH_STUDY_STIMS_BEGIN,
//   FETCH_STUDY_STIMS_SUCCESS,
//   FETCH_STUDY_STIMS_ERROR,

// };
